import React from 'react'
import BackButton from "../common/BackButton";
import Axios from "axios";
import {API} from "../../api/APIClient";
import {
    format,
    addMonths,
    subMonths,
    startOfMonth,
    endOfMonth,
    endOfWeek,
    startOfWeek,
    subWeeks,
    addWeeks,
    addDays,
    subDays,
    addYears,
    subYears,
    endOfDay,
    startOfDay,
    startOfYear,
    endOfYear
} from 'date-fns';
import { formatCurrency } from  "../../resources/Formatters";
import DatePicker from "react-datepicker";



const TIMEFRAMES = {
    DAILY: 'Daily',
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly',
    YEARLY: 'Yearly',
    CUSTOM: 'Custom',
};

class InvoiceOverview extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            timeframe:  TIMEFRAMES.MONTHLY,
            invoices:[],
            currentDate : new Date(),
            customStartDate : startOfDay(new Date()),
            customEndDate : endOfDay(new Date()),
            total: 0,
            unpaidCount: 0,
            totalUnpaid: 0,
            paidCount: 0,
            totalPaid: 0
        }

        this.handleChange = this.handleChange.bind(this);

    }


    handleNextPeriod = () => {

        this.setState((prevState) => {
            var newDate = prevState.currentDate
            switch (prevState.timeframe) {
                case TIMEFRAMES.DAILY:
                    newDate = addDays(newDate, 1);
                    break;
                case TIMEFRAMES.WEEKLY:
                    newDate = addWeeks(newDate, 1);
                    break;
                case TIMEFRAMES.MONTHLY:
                    newDate = addMonths(newDate, 1);
                    break;
                case TIMEFRAMES.YEARLY:
                    newDate = addYears(newDate, 1);
                    break;

            }

            return {
                currentDate:  newDate
            };
        }, this.recalcTotals);
    };

    handlePreviousPeriod = () => {
        this.setState((prevState) => {
            var newDate = prevState.currentDate

            switch (prevState.timeframe) {
                case TIMEFRAMES.DAILY:
                    newDate = subDays(newDate, 1);
                    break;
                case TIMEFRAMES.WEEKLY:
                     newDate = subWeeks(newDate, 1);
                    break;
                case TIMEFRAMES.MONTHLY:
                    newDate = subMonths(newDate, 1);
                    break;
                case TIMEFRAMES.YEARLY:
                    newDate = subYears(newDate, 1);
                    break;
            }

            return {
                currentDate:  newDate
            };
        }, this.recalcTotals);
    };

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;


        this.setState({
            [name]: value
        }, this.recalcTotals);



    }


    componentDidMount() {

        this.getInvoices();
    }


    recalcTotals = () => {

        const { currentDate, invoices, timeframe, customStartDate, customEndDate } = this.state;

        var startOfCurrentPeriod = startOfYear(currentDate);
        var endOfCurrentPeriod  = endOfYear(currentDate);

        switch (timeframe) {
            case TIMEFRAMES.DAILY:
                startOfCurrentPeriod = startOfDay(currentDate);
                endOfCurrentPeriod  = endOfDay(currentDate);
                break;
            case TIMEFRAMES.WEEKLY:
                startOfCurrentPeriod = addDays(startOfWeek(currentDate),1);
                 endOfCurrentPeriod  = addDays(endOfWeek(currentDate),1);
                break;
            case TIMEFRAMES.MONTHLY:
                startOfCurrentPeriod = startOfMonth(currentDate);
                endOfCurrentPeriod  = endOfMonth(currentDate);
                break;
            case TIMEFRAMES.YEARLY:
                startOfCurrentPeriod = startOfYear(currentDate);
                endOfCurrentPeriod  = endOfYear(currentDate);
                break;
            case TIMEFRAMES.CUSTOM:
                startOfCurrentPeriod = customStartDate;
                endOfCurrentPeriod  = customEndDate;
                break;
        }

        const total = invoices
            .filter(invoice => {
                const invoiceDate = new Date(invoice.dateTime * 1000);
                return invoiceDate >= startOfCurrentPeriod && invoiceDate <= endOfCurrentPeriod;
            })
            .reduce((sum, invoice) => sum + parseFloat(invoice.total), 0);

        const totalUnpaid = invoices
            .filter(invoice => {
                const invoiceDate = new Date(invoice.dateTime * 1000);
                return invoiceDate >= startOfCurrentPeriod && invoiceDate <= endOfCurrentPeriod && invoice.isPaid === 0 ;
            })

        const unpaidCount = totalUnpaid.length
        const totalUnpaidAmount = totalUnpaid.reduce((sum, invoice) => sum + parseFloat(invoice.total), 0);


        const totalPaid = invoices
            .filter(invoice => {
                const invoiceDate = new Date(invoice.dateTime * 1000);
                return invoiceDate >= startOfCurrentPeriod && invoiceDate <= endOfCurrentPeriod && invoice.isPaid === 1 ;
            })

        const paidCount = totalPaid.length
        const totalPaidAmount = totalPaid.reduce((sum, invoice) => sum + parseFloat(invoice.total), 0);


        this.setState({
            total: total,
            totalUnpaid: totalUnpaidAmount,
            unpaidCount: unpaidCount,
            totalPaid: totalPaidAmount,
            paidList:totalPaid,
            unPaidList:totalUnpaid,
            paidCount: paidCount,
            periodStart: Math.floor(startOfCurrentPeriod.getTime() / 1000) ,
            periodEnd: Math.floor(endOfCurrentPeriod.getTime() / 1000)
        } );
    }


    dateChanged = (date) => {

        if (this.state.editingStart) {
            this.setState(prevState => ({
                customStartDate: date,
                editingDate: date,
            }));
        } else {
            this.setState(prevState => ({
                customEndDate: date,
                editingDate: date,
            }));
        }


    }


    showEditStart = () => {

        this.setState(prevState => ({

            editingDate: prevState.customStartDate,
            editingStart: true,
            showDateEditor: true

        }));

    }
    showEditEnd = () => {

        this.setState(prevState => ({

            editingDate: prevState.customEndDate,
            editingStart: false,
            showDateEditor: true

        }));
    }

    hideDateEditor = () => {


        this.setState(prevState => ({

            showDateEditor: false

        }), this.recalcTotals());
    }


    getInvoices = () => {

        const formData = new FormData();
        formData.append('userId',this.props.user.id);


        Axios
            .post(API.office.getInvoices, formData)
            .then((res) => {

                if (res.data.success) {

                     this.setState({
                        invoices:res.data.data,
                        loading : false
                    },
                         this.recalcTotals)
                } else {
                    this.setState({
                        invoices:[],
                        loading : false
                    })
                }
            }).catch(error => {


            console.log(error.response)

        });
    }

    viewUnpaid = () => {



        this.props.paidUnPaidClickHandler(this.state.unPaidList)
    };


    viewPaid = () => {

        this.props.paidUnPaidClickHandler(this.state.paidList)

    };

    getExport = () => {
         Axios.get(API.office.getCSVForPeriod + this.state.periodStart + "/" + this.state.periodEnd, {
            responseType: 'blob'  
        })
            .then((res) => {
                const csvBlob = new Blob([res.data], { type: 'text/csv' });
                const csvUrl = URL.createObjectURL(csvBlob);

                const link = document.createElement('a');
                link.href = csvUrl;
                link.setAttribute('download', 'export.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    render() {


        return(

            <div className="main-wrap">
                <div className="cell small-12 "><BackButton backHandler={this.props.backHandler} /></div>
                <div className="cell small-12 ">

                    <div className="invoice">


                        <div className="header">
                            <h1 className="head-title office">Invoices</h1>
                        </div>



                        <div className="content">



                            <div className="cell small-12 no-pad" >
                                <div className="cell small-6  no-side-pad">
                                    <div><div className="bold-text form-title">Showing invoices for time frame:</div>
                                        <div>.</div>
                                        <select className="inv-select" value={this.state.timeframe} name="timeframe" onChange={this.handleChange}>
                                            {Object.entries(TIMEFRAMES).map(([key, value]) => (
                                                <option key={key} value={value}>
                                                    {value}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="cell small-6  ">
                                    <div style={{backgroundColor:"#e5e5e5"}}>

                                        {this.state.timeframe === TIMEFRAMES.CUSTOM ? (
                                            <div className="custom-date-range">
                                                <div onClick={this.showEditStart} className="ga-button">{format(this.state.customStartDate, 'dd/MM/yyyy')}</div>
                                                <span className="to-text">to</span>
                                                <div onClick={this.showEditEnd} className="ga-button">{format(this.state.customEndDate, 'dd/MM/yyyy')}</div>
                                            </div>
                                        ) : (
                                            <div className="date-range">
                                                <span className="invoice-prev" onClick={this.handlePreviousPeriod}></span>
                                                {this.state.timeframe === TIMEFRAMES.MONTHLY && format(this.state.currentDate, 'MMMM yyyy')}
                                                {this.state.timeframe === TIMEFRAMES.WEEKLY && (
                                                    <span>
        {format(addDays(startOfWeek(this.state.currentDate),1), 'dd MMMM yyyy')} - {format(addDays(endOfWeek(this.state.currentDate),1), 'dd MMMM yyyy')}
      </span>
                                                )}
                                                {this.state.timeframe === TIMEFRAMES.DAILY && format(this.state.currentDate, 'dd MMMM yyyy')}
                                                {this.state.timeframe === TIMEFRAMES.YEARLY && format(this.state.currentDate, 'yyyy')}
                                                <span className="invoice-next" onClick={this.handleNextPeriod}></span>
                                            </div>
                                        )}
                                        <div className="bold-text form-title" style={{paddingLeft:"12px"}}>Invoice Total</div>
                                        <div className="inv-total">{formatCurrency(this.state.total)}</div>
                                    </div>
                                </div>



                            </div>


                            <div className="cell small-12 no-pad ">
                                <div className="cell small-6  no-side-pad">
                                    <div   className={this.state.totalUnpaid === 0 ? "cell small-12 grey-head":"cell small-12 red-head"} >
                                        <div>
                                            <div className="bold-text" style={{ color: this.state.totalUnpaid === 0 ? "black" : "white" ,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'}} ><span>{this.state.unpaidCount} Unpaid invoices</span> <span>Total {formatCurrency(this.state.totalUnpaid)}</span></div>
                                         </div>
                                    </div>


                                    <div className="cell small-12  no-side-pad text-center">
                                        <div onClick={this.viewUnpaid} className="ga-button centered"  style={{marginRight:"0", width:"80%", opacity : this.state.totalUnpaid > 0 ?  1 :  0.5}}>View unpaid invoices for this period</div>
                                        <div onClick={this.props.custListHandler} className="ga-button"  style={{marginRight:"0", width:"80%",marginTop:"12px"}}  >View all invoices by customer</div>

                                    </div>
                                </div>

                                <div className="cell small-6">
                                    <div className="cell small-12 grey-head">
                                        <div>
                                            <div className="bold-text">{this.state.paidCount} paid invoices  Total {formatCurrency(this.state.totalPaid)}</div>
                                        </div>
                                    </div>
                                    <div className="cell small-12 centered  no-side-pad text-center">
                                        <div onClick={this.viewPaid} className="ga-button"  style={{marginRight:"0", width:"80%",opacity : this.state.totalPaid > 0 ? 1 : 0.5}}>View paid invoices for this period</div>
                                        <div onClick={this.getExport} className="ga-button"  style={{marginRight:"0", width:"80%",marginTop:"12px"}}>Export date period to CSV</div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                {this.state.loading && (
                    <div className="modal">
                        <div className="modal-content">
                            <h2>Loading...</h2>


                            <div className="cell small-12">Loading invoice data.</div>

                            &nbsp;

                        </div>
                    </div>
                )}

                {this.state.showDateEditor && (
                    <div className="modal">
                        <div className="modal-content">
                            <h2>Select a Date</h2>

                            <label>
                                <DatePicker
                                    className="edit-field"
                                    selected={this.state.editingDate}
                                    onChange={this.dateChanged}
                                    name="dateTime"
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Select a date"
                                />
                            </label>
                            <div>&nbsp;</div>
                            <div>&nbsp;</div>
                            <div>&nbsp;</div>
                            <div>&nbsp;</div>
                            <div>&nbsp;</div>
                            <div>&nbsp;</div>                            <div>&nbsp;</div>

                            <div className="cell small-12 centered"><div onClick={this.hideDateEditor} className="ga-button"  style={{width: "80%"}}>OK</div></div>




                            <div>&nbsp;</div>



                        </div>
                    </div>
                )}


            </div>
        );

    }
}
export default InvoiceOverview;