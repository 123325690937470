import React from 'react'
import CustomerList from "./CustomerList";
import Reminders from "./Reminders";
import GACalendar from "./GACalendar";
import OfficeMenu from "./OfficeMenu";
import InvoiceEditor from "./InvoiceEditor"
import InvoiceOverview from "./InvoiceOverview"
import InvoiceList from  "./InvoiceList"
import QuotesList from  "./QuotesList"
import QuoteEditor from  "./QuoteEditor"
import FormsList from "./FormsList"
import Axios from "axios";
import {API} from "../../api/APIClient";
import {formatCurrency} from "../../resources/Formatters";
import {format} from "date-fns";

class OfficeHome extends React.Component {

    constructor(props) {
        super(props);

        this.SCREENS = {
            HOME : "home",
            CUSTOMERS : "customers",
            REMINDERS: "reminders",
            CALENDAR: "calendar",
            PREV_FORMS:"previous_forms",
            PREV_INVOICES:"previous_invoices",
            PREV_QUOTES:"previous_quotes",
            CREATE_QUOTE: "new_quote",
            CREATE_INVOICE: "new_invoice",
            EDIT_INVOICE: "edit_invoice",
            EDIT_QUOTE: "edit_quote",
            CUSTOMER_LIST: "customer_list",
            INVOICE_LIST: "invoice_list",
            INVOICE_LIST_COPY: "invoice_list_copy",
            QUOTES_LIST: "quotes_list",
            COPY_INVOICE: "copy_invoice",
            COPY_QUOTE: "copy_quote"
        };

        this.state = {
            currentScreen: this.SCREENS.HOME,
            navStack: [],
            showQuoteOptions : false
        };
    }

    handleCustomerSelection (cust) {

        if (this.state.action === this.SCREENS.EDIT_INVOICE){

            this.setState({
                selectedCustomer: cust,
                currentScreen: this.SCREENS.EDIT_INVOICE
            });
        } else  if (this.state.action === this.SCREENS.PREV_INVOICES) {

            this.setState({
                selectedCustomer: cust,
                currentScreen: this.SCREENS.INVOICE_LIST
            });
        } else  if (this.state.action === this.SCREENS.PREV_FORMS) {

            this.setState({
                selectedCustomer: cust,
                currentScreen: this.SCREENS.PREV_FORMS
            });
        } else  if (this.state.action === this.SCREENS.PREV_QUOTES) {

            this.setState({
                selectedCustomer: cust,
                currentScreen: this.SCREENS.QUOTES_LIST
            });

        } else if (this.state.action === this.SCREENS.COPY_INVOICE) {

            this.setState({
                copyCustomer: cust,
                currentScreen: this.SCREENS.INVOICE_LIST_COPY
            });

        }  else if (this.state.action === this.SCREENS.COPY_QUOTE) {

                this.setState({
                      copyCustomer: cust,
                      currentScreen: this.SCREENS.COPY_QUOTE
                  });

        } else  if (this.state.action === this.SCREENS.CREATE_INVOICE) {

            if (cust.invoiceOption !== undefined && cust.invoiceOption === "copy")
            {
                cust.invoiceOption = undefined
                this.setState({
                    selectedCustomer: cust,
                    selectedInvoice: undefined,
                    action:this.SCREENS.COPY_INVOICE,
                    currentScreen: this.SCREENS.CUSTOMER_LIST
                });

            } else if (cust.invoiceOption !== undefined && cust.invoiceOption === "quote"){

                cust.invoiceOption = undefined
                this.setState({
                    selectedCustomer: cust,
                    selectedInvoice: undefined,
                    action:this.SCREENS.COPY_QUOTE,
                    currentScreen: this.SCREENS.COPY_QUOTE
                });


            } else {
                this.setState({
                    selectedCustomer: cust,
                    selectedInvoice: undefined,
                    currentScreen: this.SCREENS.EDIT_INVOICE
                });
            }

        } else  if (this.state.action === this.SCREENS.CREATE_QUOTE) {

            this.setState({
                selectedCustomer: cust,
                selectedQuote: undefined,
                currentScreen: this.SCREENS.EDIT_QUOTE
            });
        } else if (this.state.action === this.SCREENS.CUSTOMERS){
            const oldScreen = this.state.currentScreen;
            const navStack = this.state.navStack.concat([oldScreen])
            this.setState({
                selectedCustomer: cust,
                currentScreen: this.SCREENS.NEW_CUSTOMER,
                navStack: navStack
            });
        }

    }


    reloadInvoices() {
        const formData = new FormData();
        formData.append('userId', this.props.user.id);

        Axios.post(API.office.getInvoices, formData)
            .then((res) => {
                if (res.data.success) {
                    const allInvoices = res.data.data;
                    const selectedIds = new Set(this.state.selectedInvoices.map(inv => inv.id));

                    const selectedInvoices = allInvoices.filter(invoice => selectedIds.has(invoice.id));

                    this.setState({ selectedInvoices });

                } else {
                    console.log(res.data.error);
                }
            })
            .catch(error => {
                console.log(error.response);
            });
    }


    handlePaidUnpadSelection (invoices) {
        const oldScreen = this.state.currentScreen;
        const navStack = this.state.navStack.concat([oldScreen])
        this.setState({
                selectedInvoices: invoices,
            selectedCustomer:undefined,
            navStack:navStack,
                currentScreen: this.SCREENS.INVOICE_LIST
            });
    }


    showPrevInvoiceCustList() {

        this.props.showMenuHandler(false);
        const oldScreen = this.state.currentScreen;
        const navStack = this.state.navStack.concat([oldScreen])
        this.setState({
            currentScreen: this.SCREENS.CUSTOMER_LIST,
            navStack: navStack,
            action:this.SCREENS.PREV_INVOICES
        });

    }

    handleQuoteClick(quote) {

        const oldScreen = this.state.currentScreen;
        const navStack = this.state.navStack.concat([oldScreen])
        this.setState({
            selectedQuote: quote,
            currentScreen: this.SCREENS.EDIT_QUOTE,
            navStack: navStack,
        });

    }

    handleInvoiceClick (invoice) {
        const oldScreen = this.state.currentScreen;
        const navStack = this.state.navStack.concat([oldScreen])
        this.setState({
            selectedInvoice: invoice,
            currentScreen: this.SCREENS.EDIT_INVOICE,
            navStack: navStack,
        });
    }

    handleInvoiceCopy (invoice) {
        this.setState({
            copyInvoice: invoice,
            currentScreen: this.SCREENS.EDIT_INVOICE
        });
    }



    handleCreateInvoiceFromQuote (quote) {
        this.setState( {
            currentScreen : this.SCREENS.EDIT_INVOICE,
            selectedQuote: quote
        })
    }


    handleListItemClick (newScreen) {
        if (newScreen === this.SCREENS.CUSTOMERS) {
            this.props.showMenuHandler(false);
            const oldScreen = this.state.currentScreen;
            const navStack = this.state.navStack.concat([oldScreen])
            this.setState({
                currentScreen: this.SCREENS.CUSTOMER_LIST,
                navStack: navStack,
                action:this.SCREENS.CUSTOMERS
            });
         } else if (newScreen === this.SCREENS.REMINDERS) {

            this.props.showMenuHandler(false);
            const oldScreen = this.state.currentScreen;
            const navStack = this.state.navStack.concat([oldScreen])
            this.setState({
                currentScreen: this.SCREENS.REMINDERS,
                navStack: navStack,
                action:this.SCREENS.REMINDERS
            });
        }else if (newScreen === this.SCREENS.CALENDAR) {

            this.props.showMenuHandler(false);
            const oldScreen = this.state.currentScreen;
            const navStack = this.state.navStack.concat([oldScreen])
            this.setState({
                currentScreen: this.SCREENS.CALENDAR,
                navStack: navStack,
                action:this.SCREENS.CALENDAR
            });
        }  else if (newScreen === this.SCREENS.PREV_FORMS) {
            this.props.showMenuHandler(false);
            const oldScreen = this.state.currentScreen;
            const navStack = this.state.navStack.concat([oldScreen])
            this.setState({
                currentScreen: this.SCREENS.CUSTOMER_LIST,
                navStack: navStack,
                action:this.SCREENS.PREV_FORMS
            });
        } else if (newScreen === this.SCREENS.CREATE_QUOTE) {

            this.props.showMenuHandler(false);
            const oldScreen = this.state.currentScreen;
            const navStack = this.state.navStack.concat([oldScreen])
            this.setState({
                currentScreen: this.SCREENS.CUSTOMER_LIST,
                navStack: navStack,
                action:this.SCREENS.CREATE_QUOTE
            });
        } else if (newScreen === this.SCREENS.CREATE_INVOICE) {

            this.props.showMenuHandler(false);
            const oldScreen = this.state.currentScreen;
            const navStack = this.state.navStack.concat([oldScreen])
            this.setState({
                currentScreen: this.SCREENS.CUSTOMER_LIST,
                navStack: navStack,
                action:this.SCREENS.CREATE_INVOICE
            });
        } else if (newScreen === this.SCREENS.EDIT_INVOICE) {

            this.props.showMenuHandler(false);
            const oldScreen = this.state.currentScreen;
            const navStack = this.state.navStack.concat([oldScreen])
            this.setState({
                currentScreen: this.SCREENS.CUSTOMER_LIST,
                navStack: navStack,
                action:this.SCREENS.EDIT_INVOICE
            });
        } else if (newScreen === this.SCREENS.PREV_INVOICES) {
            this.props.showMenuHandler(false);
            const oldScreen = this.state.currentScreen;
            const navStack = this.state.navStack.concat([oldScreen])
            this.setState({
                currentScreen: this.SCREENS.PREV_INVOICES,
                navStack: navStack
            });
        } else if (newScreen === this.SCREENS.PREV_QUOTES) {
             this.setState({
                showQuoteOptions: true
            });
        }



    }

    componentDidMount()
    {
        this.props.showMenuHandler(true);

    }

    handleQuoteCopyDestroy() {
        this.setState({ selectedQuote: undefined });

    }
    handleInvoiceCopyDestroy() {
        this.setState({ copyInvoice: undefined });

    }

    handleBackClick ()
    {
        const navStack = this.state.navStack.slice();

        if (navStack.length > 1)
        {
            this.props.showMenuHandler(false);
        } else {
            this.props.showMenuHandler(true);
         }
        const backScreen = navStack[navStack.length - 1];

        this.setState({
            currentScreen: backScreen,
            navStack: navStack.slice(0,navStack.length - 1)
        });
    }

    hideQuoteOptions = () => {
        this.setState((prevState) => ({
            showQuoteOptions: false
        }));
    }

    quotesByCustomer = () => {
        this.props.showMenuHandler(false);
        const oldScreen = this.state.currentScreen;
        const navStack = this.state.navStack.concat([oldScreen])
        this.setState({
            currentScreen: this.SCREENS.CUSTOMER_LIST,
            navStack: navStack,
            action:this.SCREENS.PREV_QUOTES,
            showQuoteOptions: false
        });

    }


    quotesByDate = () => {

        this.setState({
            selectedCustomer: undefined,
            currentScreen: this.SCREENS.QUOTES_LIST,
            showQuoteOptions: false

        });
    }


    render() {

        if (this.state.showQuoteOptions) {

            return(

                <div className="main-wrap">
                    <div className="modal">
                        <div className="modal-content">
                            <h2>View by...</h2>


                             <div className="cell small-12 centered modal-btn-wrap"><div onClick={this.quotesByCustomer} className="ga-button modal-btn">View By Customer</div></div>
                            <div className="cell small-12 centered modal-btn-wrap"><div onClick={this.quotesByDate} className="ga-button modal-btn">View By Date</div></div>

                            <div className="cell small-12 centered modal-btn-wrap"><div onClick={this.hideQuoteOptions} className="ga-button modal-btn" style={{background:"#999"}}>Cancel</div></div>
                            


                        </div>
                    </div>
                </div>
            );


        } else {
            switch (this.state.currentScreen) {

                case this.SCREENS.HOME :
                    return (<OfficeMenu sectionClickHandler={(newScreen) => this.handleListItemClick(newScreen)}  SCREENS={this.SCREENS} />);

                case this.SCREENS.CUSTOMER_LIST:
                    return (<CustomerList  screens={this.SCREENS} customerClickHandler={(customer) => this.handleCustomerSelection(customer)}  action={this.state.action} backHandler={() => this.handleBackClick()}  reloadHandler={() => this.reload()} />);

                case this.SCREENS.REMINDERS:
                    return (<Reminders backHandler={() => this.handleBackClick()}  reloadHandler={() => this.reload()} />);

                case this.SCREENS.CALENDAR:
                    return (<GACalendar backHandler={() => this.handleBackClick()}  reloadHandler={() => this.reload()} />);

                case this.SCREENS.EDIT_INVOICE:
                    return (<InvoiceEditor backHandler={() => this.handleBackClick()} quote={this.state.selectedQuote}  user={this.props.user} invoice={this.state.selectedInvoice} customer={this.state.selectedCustomer} copyInvoice={this.state.copyInvoice} quoteCopyHandler={() => this.handleQuoteCopyDestroy()} invoiceCopyHandler={() => this.handleInvoiceCopyDestroy()} /> );

                case this.SCREENS.EDIT_QUOTE:
                    return (<QuoteEditor backHandler={() => this.handleBackClick()}  user={this.props.user} quote={this.state.selectedQuote} customer={this.state.selectedCustomer} createInvoiceHandler={(quote) => this.handleCreateInvoiceFromQuote(quote)}/> );

                case this.SCREENS.PREV_INVOICES:
                    return (<InvoiceOverview backHandler={() => this.handleBackClick()} paidUnPaidClickHandler={(invoices) => this.handlePaidUnpadSelection(invoices)}  user={this.props.user} custListHandler={() => this.showPrevInvoiceCustList()}  /> );

                case this.SCREENS.INVOICE_LIST:
                    return (<InvoiceList backHandler={() => this.handleBackClick()} invoices={this.state.selectedInvoices} forceRefreshHandler={() => this.reloadInvoices()}  user={this.props.user} invoiceHandler={(invoice) => this.handleInvoiceClick(invoice)} customer={this.state.selectedCustomer}  /> );

                case this.SCREENS.INVOICE_LIST_COPY:
                    return (<InvoiceList backHandler={() => this.handleBackClick()} invoices={this.state.selectedInvoices}   user={this.props.user} invoiceHandler={(invoice) => this.handleInvoiceCopy(invoice)} customer={this.state.copyCustomer}  /> );

                case this.SCREENS.COPY_QUOTE:
                    return (<QuotesList backHandler={() => this.handleBackClick()} user={this.props.user} quotesHandler={(quote) => this.handleCreateInvoiceFromQuote(quote)} customer={this.state.selectedCustomer}  /> );

                case this.SCREENS.QUOTES_LIST:
                    return (<QuotesList backHandler={() => this.handleBackClick()} user={this.props.user} quotesHandler={(quote) => this.handleQuoteClick(quote)} customer={this.state.selectedCustomer}  /> );

                case this.SCREENS.PREV_FORMS:
                    return (<FormsList backHandler={() => this.handleBackClick()} user={this.props.user}  customer={this.state.selectedCustomer}  /> );


                default:
                    return (<OfficeMenu sectionClickHandler={(newScreen) => this.handleListItemClick(newScreen)}  SCREENS={this.SCREENS} />);

            }
        }



    }
}

export default OfficeHome;
