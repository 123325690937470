import React from 'react'
import BackButton from "../common/BackButton";
import Axios from "axios";
import {API} from "../../api/APIClient";
import Calendar from 'react-calendar'
import "react-calendar/dist/Calendar.css"
import "../../assets/css/calendarOverrides.css";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
 import SimpleAlertDialog, {ModalButton} from "../common/SimpleAlertDialog";
import NewCustomer from "./NewCustomer";



const createEmptyEvent = () => ({

    dateTime: Math.floor(Date.now() / 1000), // Current timestamp in seconds
    title: ''

});


class GACalendar extends React.Component {

    constructor(props) {
        super(props);

        this.SCREENS = {
            HOME: "calendar",
            NEW_CUSTOMER: "newcustomer"
        };

        this.state = {
            currentScreen: this.SCREENS.HOME,
            navStack: [],
            calEvents: [],
            todays:[],
            engineerTodays:[],
            activeDate:new Date(),
            loading: true,
            alertModalOpen : false,
            alertModalTitle : null,
            alertModalMessage: null,
            alertModalButtons : [],
            canSubmit: false,
            displayCustomers : [],
            customers: [],
            customerToDisplay: null,
            team: null,
            engineers: [],
            dateTime: Date.now(),
            engineerToDisplay: null
        }

        this.handleChange = this.handleChange.bind(this);

    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        if(name === "engineerToDisplay") {
            this.setState(prevState => ({
                [name]: value
            }),() =>  {
                    this.refreshTodaysEvents()
            });
        } else {
            this.setState(prevState => ({
                eventToEdit: {
                    ...prevState.eventToEdit,
                    [name]: value
                }
            }));
        }


    }

    componentDidMount() {
        this.getEvents();
        this.getTeamData();
        this.getCustomers();
     }



    dateChanged = (date) => {
        const timestampInSeconds = Math.floor(date.getTime() / 1000);
        this.setState(prevState => ({
            eventToEdit: {
                ...prevState.eventToEdit,
                dateTime: timestampInSeconds
            },
            dateTime: date.getTime()

        }));

    }

    hideAlertModal() {
        this.setState({
            alertModalOpen : false
        });
    }

    deleteEvent() {
        const formData = new FormData();
        formData.append('eventId',this.state.eventToShow.id);

        Axios
            .post(API.office.deleteCalendarEvent, formData)
            .then((res) => {

                if (res.data.success) {


                    this.setState({
                        eventToShow:undefined,
                     },this.getEvents)
                } else {
                    this.setState({


                    })
                }
            })
    }

    promptForDelete() {
        this.showAlertModal(
            "Delete Event",
            "Are you sure? This event will be permanently removed.",
            [
                {
                    label : "Delete ",
                    onClick : () => { this.hideAlertModal(); this.deleteEvent(); },
                    className : "success"
                },
                {
                    label : "Cancel",
                    onClick : () => { this.hideAlertModal() }
                }
            ]
        );
    }



    showAlertModal(title, message, buttons) {
        this.setState({
            alertModalOpen : true,
            alertModalTitle : title,
            alertModalMessage : message,
            alertModalButtons : buttons
        });
    }



    getMonthStartEndTimestamps(activeDate) {
        // Parse the active date
        const date = new Date(activeDate);

        // Get the year and month
        const year = date.getFullYear();
        const month = date.getMonth();

        // Create date objects for the start and end of the month
        const startOfMonth = new Date(year, month, 1);
        const endOfMonth = new Date(year, month + 1, 0, 23, 59, 59, 999);

        // Convert to timestamps in seconds
        const startTimestamp = Math.floor(startOfMonth.getTime() / 1000);
        const endTimestamp = Math.floor(endOfMonth.getTime() / 1000);

        return { startTimestamp, endTimestamp };
    }



    getEvents = () => {

        const { startTimestamp, endTimestamp } = this.getMonthStartEndTimestamps(this.state.activeDate);

        const formData = new FormData();
        formData.append('endDate',endTimestamp);
        formData.append('startDate',startTimestamp);

        Axios
            .post(API.office.getCalendarEvents, formData)
             .then((res) => {

                 if (res.data.success) {
                     this.setState({
                         calEvents:res.data.data,
                          loading : false
                     },this.refreshTodaysEvents)
                 } else {
                     this.setState({

                         calEvents: [],
                         loading: false
                     })
                 }
             })
    }



    getTeamData = () => {
        Axios
             .get(API.office.getTeamData)
             .then((res) => {

                 if (res.data.success && res.data.data != null) {
                     this.setState({
                         team : res.data.data.team,
                         loading : false
                     })

                     if(this.state.team != null) {
                         this.getEngineers()
                     }
                 } else {
                     this.setState({
                         loading: false
                     })
                 }


             })
    }

    getEngineers = () => {
        Axios
            .get(API.office.getEngineers)
            .then((res) => {

                if (res.data.success) {
                    this.setState({
                        engineers : res.data.data.map((engineer) => <option value={engineer.userID}>{engineer.name}</option>),
                        loading : false
                    })
                } else {
                    this.setState({
                        loading: false
                    })
                }


            })
    }

    getCustomers = () => {
        Axios
            .get(API.office.getCustomers)
            .then((res) => {

                if (res.data.success) {
                    this.setState({
                        loading : false,
                        displayCustomers : res.data.data.map((customer) => <option value={customer.customerID}>{customer.firstname + " " + customer.surname}</option>),
                        customers : res.data.data
                    })
                } else {
                    this.setState({
                        loading: false
                    })
                }


            })
    }

    onMonthChange = (details) => {
        this.setState((prevState) => {
            const prevDay = prevState.activeDate.getDate();

            const year = details.activeStartDate.getFullYear();
            const month = details.activeStartDate.getMonth();

            // Find the last valid day of the new month (handles cases like Feb 31st)
            const lastDayOfNewMonth = new Date(year, month + 1, 0).getDate();
            const newDay = Math.min(prevDay, lastDayOfNewMonth);

            return {
                details: details,
                waz: prevState.activeDate,
                activeDate: new Date(year, month, newDay),
            };
        }, this.getEvents);
    };



    formatWeekday = (locale, date) => {
        return format(date, 'EEEEEE', { locale }).charAt(0);
    };

    dayClicked = (value, event) => {

        this.setState({
            activeDate:value
        },this.refreshTodaysEvents)

    };

    tileContent = (tile) => {

        const tileDate = format(tile.date, 'dd-MM-yyy', {  })
        const daysEvents = this.state.calEvents.filter(calEvent => {
            const eventDate = format( calEvent.eventDate, 'dd-MM-yyy', {  })
            return eventDate === tileDate;
        });

        if(Number(this.state.engineerToDisplay) > 0) {
            const engineerDaysEvents = daysEvents.filter(
                item => {
                    return item.userId === Number(this.state.engineerToDisplay);
                });
            return engineerDaysEvents.length > 0 ? <div>.</div> : <div></div>
        } else {
            return daysEvents.length > 0 ? <div>.</div> : <div></div>
        }
    };


    refreshTodaysEvents() {

        const activeDate = format(this.state.activeDate, 'dd-MM-yyy', {  });

        let todaysEvents = this.state.calEvents.filter(calEvent => {
            const eventDate = format( calEvent.eventDate, 'dd-MM-yyy', {  })
            return eventDate === activeDate;
        });

        if (Number(this.state.engineerToDisplay) > 0) {
            const engineerEvents = todaysEvents.filter(
                item => {
                    return item.userId === Number(this.state.engineerToDisplay);
                });
            this.setState({ todays: engineerEvents.map((calEvent) => {
                let calCustomer = null
                    if(calEvent.customerId != null) {
                        calCustomer = this.state.customers.find((customer) => customer.customerID === calEvent.customerId)
                    }

                    return (
                        <div className="list-item" onClick={() => this.viewEventClicked(calEvent)}>
                            <div className="event-container">
                                <div className="event-time">{format(calEvent.eventDate, 'HH:mm')}</div>

                                <div className="event-details">
                                    <div className="name-highlight" style={{backgroundColor : `#${calEvent.colour}`}}>{calEvent.givenname + " " + calEvent.familyname}</div>
                                    <span>{calEvent.title}</span>
                                    {calCustomer && (
                                        <>
                                            <span>{calCustomer.firstname + " " + calCustomer.surname}</span>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                }) });
        } else {
            this.setState({ todays: todaysEvents.map((calEvent) => {
                    let calCustomer = null
                    if(calEvent.customerId != null) {
                        calCustomer = this.state.customers.find((customer) => customer.customerID === calEvent.customerId)
                    }


                    return (
                        <div className="list-item" onClick={() => this.viewEventClicked(calEvent)}>
                            <div className="event-container">
                                <div className="event-time">{format(calEvent.eventDate, 'HH:mm')}</div>

                                <div className="event-details">
                                    <div className="name-highlight"  style={{backgroundColor :  `#${calEvent.colour}`}}>{calEvent.givenname + " " + calEvent.familyname}</div>
                                    <span>{calEvent.title}</span>
                                    {calCustomer && (
                                        <>
                                            <span>{calCustomer.firstname + " " + calCustomer.surname}</span>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    );

                }) });
        }


    }


    sendEmailReminder(calEvent) {
        let endpoint = API.office.sendEventReminder;

        const formData = new FormData();

        formData.append('eventId', calEvent.id);

        Axios
            .post(endpoint, formData)
            .then((res) => {

                alert("Email reminder sent")

            })
            .catch((error) => {
                this.setState((prevState) => ({
                    showSpinner: false,
                    eventToEdit: null
                }));
            });
    }

    editEventClicked(calEvent) {

        if (calEvent === undefined) {
            calEvent = createEmptyEvent()
        } else {
            this.setState({
                eventToShow:undefined
            })
        }

        if (calEvent.userId){
            calEvent.selectedEngineer = String(calEvent.userId)
        }
        if (calEvent.customerId){
            calEvent.selectedCustomer = calEvent.customerId
        }

        this.setState({
            eventToEdit:calEvent
        })

        if (calEvent?.eventDate) {
            const eventTimestamp =new Date(calEvent.eventDate).getTime() ;
            this.setState({
                dateTime:eventTimestamp
            })
        }

    }

    viewEventClicked(calEvent) {
        let customer = null
        if(calEvent.customerId != null) {
            customer = this.state.customers.find((customer) => customer.customerID === calEvent.customerId)
        }

        this.setState({
            eventToShow:calEvent,
            customerToDisplay : customer
        })

    }

    hideEventEdit = () => {

        this.setState({
            eventToEdit:undefined
        })

    }

    hideEventView = () => {

        this.setState({
            eventToShow:undefined
        })

    }

    hideSendResult = () => {
        this.setState((prevState) => ({
            showSendResult: false
        }));
    }

    showSendResult = () => {
        this.setState((prevState) => ({
            showSendResult: true
        }));
    }

    submitEvent = () => {

        let errorMessage = null;
        if(this.state.eventToEdit.title === null) {
            errorMessage = "Please provide a title for the event"
        } else if(this.state.eventToEdit.description === null) {
            errorMessage = "Please provide a description for the event"
        }

        if(errorMessage != null) {
            alert(errorMessage)
        } else {
            const formData = new FormData();
            formData.append('eventDateTime', this.state.dateTime / 1000);

            formData.append('eventTitle', this.state.eventToEdit.title);
            formData.append('eventDescription', this.state.eventToEdit.description);

            if(this.state.eventToEdit.selectedCustomer != null) {
                formData.append("customerId", this.state.eventToEdit.selectedCustomer)
            }

            if(this.state.eventToEdit.selectedEngineer > 0) {
                formData.append("engineerId", this.state.eventToEdit.selectedEngineer)
            }

            let endpoint = API.office.updateCalendarEvent;
            if (this.state.eventToEdit.id === undefined) {
                endpoint = API.office.addCalendarEvent
            } else {
                formData.append('eventId', this.state.eventToEdit.id);
            }
            Axios
                .post(endpoint, formData)
                .then((res) => {
                    this.setState((prevState) => ({
                            showSendResult: true,
                            showSpinner: false,
                            eventToEdit: null
                        }));
                    this.getEvents();

                })
                .catch((error) => {
                    this.setState((prevState) => ({
                        showSpinner: false,
                        eventToEdit: null
                    }));
                });
        }
    }

    handleBackClick ()
    {
        const navStack = this.state.navStack.slice();
        const backScreen = navStack[navStack.length - 1];

        this.setState({
            currentScreen: backScreen,
            navStack: navStack.slice(0,navStack.length - 1)
        });
    }

    reloadAndHandleBackClick ()
    {
        this.handleBackClick();
        this.getCustomers();
    }

    newCustClicked = () => {
        console.log("newCustClicked")
        const oldScreen = this.state.currentScreen;
        const navStack = this.state.navStack.concat([oldScreen])
        this.setState({
            currentScreen: this.SCREENS.NEW_CUSTOMER,
            navStack: navStack,
            selectedCustomer:undefined
        });
    }

    goToToday = () => {
        this.setState({
            activeDate: new Date()
        },this.refreshTodaysEvents)


    }

    render() {

        switch (this.state.currentScreen) {
            case this.SCREENS.HOME:

                return (

                    <div className="main-wrap">
                        <div className="cell small-12"><BackButton backHandler={this.props.backHandler}/></div>

                        <div className="cell small-12 ">

                            <div className="calendar">

                                <div className="header">
                                    <h1 className="head-title office">Calendar</h1>
                                </div>

                                <div className="content">
                                    <div className="cell small-6 no-pad">
                                        <div onClick={() => this.goToToday()} className="ga-button">Today</div>
                                        <div>&nbsp;</div>
                                        <div className="CalendarView">
                                            <Calendar onActiveStartDateChange={this.onMonthChange}
                                                      formatShortWeekday={this.formatWeekday}
                                                      onClickDay={this.dayClicked}
                                                      tileContent={this.tileContent}
                                                      prev2Label={null}
                                                      next2Label={null}
                                                      minDetail={"month"}
                                                      value={this.state.activeDate}
                                                      activeStartDate={new Date(this.state.activeDate.getFullYear(), this.state.activeDate.getMonth(), 1)}
                                            />
                                        </div>
                                    </div>

                                    {this.state.engineers.length > 0 &&
                                    <div className="cell small-6 no-pad">

                                        <div className="cell small-12">
                                            <label>
                                                <h3>Filter by Engineer</h3>
                                                <select className="cell small-12" value={this.state.engineerToDisplay}
                                                        name="engineerToDisplay"
                                                        onChange={this.handleChange}>
                                                    <option value="engineerToDisplay"> Everyone</option>
                                                    {this.state.engineers}
                                                </select>
                                            </label>
                                        </div>

                                    </div>
                                    }

                                    <div className="cell small-6 cal-events">

                                        <div className="item-header">{format(this.state.activeDate, 'd MMM yyy', {})}</div>

                                        {this.state.todays}

                                        <div className="cell small-12 ga-button" onClick={() => this.editEventClicked()}>Add new event</div>

                                    </div>

                                </div>
                            </div>
                        </div>


                        {this.state.eventToShow && (
                            <div className="modal">
                                <div className="modal-content">

                                    <div
                                        className="cell small-12" style={{
                                        paddingLeft: "15px",
                                        paddingRight: "15px", backgroundColor:"#e5e5e5"
                                    }}>{format(this.state.eventToShow.eventDate, 'd MMM yyy - HH:mm', {})}</div>
                                    <div className="cell small-12 no-pad bold-text" style={{
                                        paddingLeft: "15px",
                                        paddingBottom: "15px",
                                        paddingRight: "15px",fontSize:"1.3em", marginBottom:"12px", backgroundColor:"#e5e5e5"
                                    }}>{this.state.eventToShow.title}</div>

                                    <h4  style={{
                                        paddingLeft: "15px",
                                        paddingRight: "15px"
                                    }}>Description</h4>
                                    <div className="cell small-12 no-pad" style={{
                                        paddingLeft: "15px",
                                        paddingRight: "15px"
                                    }}>{this.state.eventToShow.description}</div>

                                    <div className="cell small-12"></div>

                                    {this.state.customerToDisplay != null &&
                                        <><h4  style={{
                                            paddingLeft: "15px",
                                            paddingRight: "15px"
                                        }}>Customer Details</h4>
                                            <div
                                            className="cell small-12">{this.state.customerToDisplay.firstname + " " + this.state.customerToDisplay.surname}</div>
                                        </>

                                    }

                                    {(this.state.team.isOwner || this.state.eventToShow.userId === Number(this.state.engineerToDisplay)) &&

                                    <div className="call small-12 no-pad">

                                        { (this.state.customerToDisplay && this.state.customerToDisplay.email!==undefined && this.state.customerToDisplay.email.length>2) &&
                                        <div className="cell small-12 centered no-pad"
                                             style={{paddingLeft: "15px", paddingRight: "15px", paddingTop: "5px"}}>
                                            <div onClick={() => this.sendEmailReminder(this.state.eventToShow)}
                                                 className="ga-button" style={{width: "70%", marginRight:"0px"}}>Send Email Reminder
                                            </div>
                                        </div>
                                        }
                                        <div className="cell small-12 centered no-pad"
                                             style={{paddingLeft: "15px", paddingRight: "15px", paddingTop: "5px"}}>
                                            <div onClick={() => this.editEventClicked(this.state.eventToShow)}
                                                 className="ga-button" style={{width: "70%", marginRight:"0px"}}>Edit
                                            </div>
                                        </div>

                                        <div className="cell small-12 centered no-pad"
                                             style={{paddingLeft: "15px", paddingRight: "15px", paddingTop: "5px"}}>
                                            <div onClick={() => this.promptForDelete()} className="ga-button"
                                                 style={{width: "70%", background: "#D85946", marginRight:"0px"}}>Delete Event
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    <div className="cell small-12 centered no-pad"
                                         style={{paddingLeft: "15px", paddingRight: "15px", paddingTop: "5px"}}>
                                        <div onClick={this.hideEventView} className="ga-button"
                                             style={{width: "70%", background: "#999", marginRight:"0px"}}>Close
                                        </div>
                                    </div>

                                    &nbsp;

                                </div>
                            </div>
                        )}

                        {this.state.eventToEdit && (
                            <div className="modal">
                                <div className="modal-content">
                                    <h2>Event Details</h2>
                                    <div className="cell small-12 no-pad  ">
                                        <div className="bold-text" style={{marginBottom:"10px"}}>Event title</div>
                                    </div>
                                    <label>
                                        <input className="edit-field" type="text" name="title" value={this.state.eventToEdit.title}
                                               onChange={this.handleChange}/>
                                    </label>

                                    <div className="cell small-12  "></div>


                                    <div className="cell small-12 no-pad bold-text" >Date</div>

                                    <label>
                                        <DatePicker
                                            className="edit-field"
                                            selected={new Date(this.state.dateTime)}
                                            onChange={this.dateChanged}
                                            name="dateTime"
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="Select a date"
                                        />
                                    </label>

                                    <div className="cell small-12  "></div>

                                    <div className="cell small-12 no-pad bold-text">Time</div>
                                    <label>
                                        <DatePicker
                                            className="edit-field"
                                            selected={new Date(this.state.dateTime)}
                                            onChange={this.dateChanged}
                                            name="dateTime"
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={1}
                                            timeCaption="Time"
                                            dateFormat="HH:mm"
                                            placeholderText="Select a time"
                                        />
                                    </label>
                                    <div className="cell small-12  "></div>

                                    <div className="cell small-12 no-pad bold-text" style={{marginBottom:"10px"}}>Description</div>
                                    <label>
                                        <textarea className="msg" type="text" name="description" placeholder=""
                                                  onChange={this.handleChange}>{this.state.eventToEdit.description}</textarea>
                                    </label>

                                    <div className="cell small-12  "></div>


                                    {this.state.displayCustomers.length > 0 &&
                                       <> <div className="cell small-12 no-pad bold-text" style={{marginBottom:"10px"}}>Customer</div>
                                            <label>
                                                <select className="cell small-12" value={this.state.eventToEdit.selectedCustomer}
                                                        name="selectedCustomer"
                                                        onChange={this.handleChange}>
                                                    <option value="selectedCustomer"> Select a Customer</option>
                                                    {this.state.displayCustomers}
                                                </select>
                                            </label>
                                       </>
                                    }


                                    <div className="cell small-12  "></div>

                                    {this.state.engineers.length > 0 &&
                                        <>
                                    <div className="cell small-12 no-pad bold-text" style={{marginBottom:"10px"}}>Engineer</div>
                                        <label>
                                            <select className="cell small-12" value={this.state.eventToEdit.selectedEngineer}
                                                    name="selectedEngineer"
                                                    onChange={this.handleChange}>
                                                <option value="selectedEngineer"> Select an Engineer</option>
                                                {this.state.engineers}
                                            </select>
                                        </label>
                                        </>
                                    }

                                    <div className="cell small-12 centered">or</div>

                                    <div className="cell small-12 no-pad centered"
                                         style={{paddingLeft: "15px", paddingRight: "15px"}}>
                                        <div onClick={this.newCustClicked} className="ga-button"
                                             style={{width: "70%"}}>Add new customer
                                        </div>
                                    </div>

                                    <div className="cell small-12  "></div>

                                    <div className="cell small-12 no-pad centered"
                                         style={{paddingLeft: "15px", paddingRight: "15px", paddingTop: "15px"}}>
                                        <div onClick={this.submitEvent} className="ga-button"
                                             style={{width: "70%"}}>Save
                                        </div>
                                    </div>
                                    <div className="cell small-12 no-pad centered"
                                         style={{paddingLeft: "15px", paddingRight: "15px", paddingTop: "5px"}}>
                                        <div onClick={this.hideEventEdit} className="ga-button"
                                             style={{width: "70%", background: "#999"}}>Cancel
                                        </div>
                                    </div>

                                    &nbsp;

                                </div>
                            </div>
                        )}

                        {this.state.showSendResult && (
                            <div className="modal">
                                <div className="modal-content" style={{textAlign: "center"}}>
                                    <h2>Event Saved</h2>
                                    <div>Event saved successfully</div>


                                    <div className="cell small-12 centered">
                                        <div onClick={this.hideSendResult} className="ga-button"
                                             style={{width: "80%"}}>OK
                                        </div>
                                    </div>


                                    &nbsp;


                                </div>
                            </div>
                        )}


                        <SimpleAlertDialog
                            open={this.state.alertModalOpen}
                            title={this.state.alertModalTitle}
                            message={this.state.alertModalMessage}>
                            {
                                this.state.alertModalButtons.map((button) => (
                                    <ModalButton label={button.label} onClick={button.onClick}
                                                 className={button.className}/>
                                ))
                            }
                        </SimpleAlertDialog>

                    </div>
                );

            case this.SCREENS.NEW_CUSTOMER:
                return(<NewCustomer backHandler={() => this.reloadAndHandleBackClick()} customer={this.state.selectedCustomer} />);

            default:
                return (<div>Unknown screen</div>)
        }

    }
}
export default GACalendar;
