import React from 'react'
import BackButton from "../common/BackButton";
import Axios from "axios";
import {API} from "../../api/APIClient";


class QuotesList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            quotes: [],
            quotesRawData: [],
            loading: true,
            searchText: ''

        }

    }


    componentDidMount() {
        this.getQuotes();
    }

    handleChange = (event) => {
        this.setState({
            searchText: event.target.value
        },() => this.filterQuotes());
    }


    filterQuotes = () => {
        if (this.state.searchText === "") {
            const quotes = this.state.quotesRawData.map((quote, c) => {
                return <div className="list-item list-arrow" onClick={() => this.props.quotesHandler(quote)}>
                    <h4>{quote.customer_name}</h4>
                    <span>{quote.addr1}</span>
                    <span>£{quote.price} <span style={{float:"right",marginRight:"100px"}}>{quote.createdDate}</span></span>
                </div>;
            });

            this.setState( {
                quotes: quotes
            })
        } else {
        const filteredQuotes = this.state.quotesRawData.filter(
                item =>
                    (item.customer_name && item.customer_name.toLowerCase().includes(this.state.searchText.toLowerCase()))
            )

            const quotes = filteredQuotes.map((quote, c) => {
                return <div className="list-item list-arrow" onClick={() => this.props.quotesHandler(quote)}>
                    <h4>{quote.customer_name}</h4>
                    <span>{quote.addr1}</span>
                    <span>£{quote.price} <span style={{float:"right",marginRight:"100px"}}>{quote.createdDate}</span></span>
                </div>;
            });

            this.setState( {
                quotes: quotes
            })
        }

    }



    getQuotes = () => {

            const formData = new FormData();
            if (this.props.customer != undefined) {
                formData.append('fname',this.props.customer.firstname);
                formData.append('sname',this.props.customer.surname);
            }

            Axios
                .post(API.office.getQuotes, formData)
                .then((res) => {

                    if (res.data.success) {


                        this.setState({
                            quotesRawData:res.data.data,
                            loading : false
                        },() => this.filterQuotes());
                    } else {
                        this.setState({
                            quotes:[],
                            loading : false
                        })
                    }
                }).catch(error => {


                console.log(error.response)

            });

    }

    render() {


        return(

            <div className="main-wrap">
                <div className="cell small-12"><BackButton backHandler={this.props.backHandler} /></div>
                <div className="cell small-12 no-pad">

                    <div className="custlist">

                        <div className="header">
                            <span className="bold-text">{this.state.quotes.length} quotes</span>
                        </div>
                        <div className="padit">
                            Select a Quote
                        </div>
                        <div className="content">
                            <div className="cell small-12">
                                <input className="search" type="text" name="searchText" placeholder="Search..." onChange={this.handleChange} value={this.state.searchText} />
                            </div>

                            <div className="cell small-12">

                                {this.state.loading ? (
                                    <div>Loading...</div>
                                ) : (
                                    <div>
                                        {this.state.quotes}
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>
                </div>


            </div>
        );

    }


}

export default QuotesList;
