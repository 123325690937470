import React from 'react'
import SectionButton from '../common/SectionButton'
import ChangeEmail from './ChangeEmail'
import ChangePassword from './ChangePassword'
import ProfilePicture from './ProfilePicture'
import Subscription from './Subscription'
import Terms from '../terms/Terms'
import { API } from '../../api/APIClient'
import Axios from 'axios'
import moment from 'moment';

class SettingsHome extends React.Component {

    constructor(props) {
        super(props);

        this.SCREENS = {
            HOME: "settingsmenu",
            CHANGE_EMAIL: "changeMyEmail",
            CHANGE_PASSWORD: "changeMePassword",
            PROFILE_PICTURE: "profilePic",
            SUBSCRIPTION: "subscription",
            TNCS: "tncs"
        };

        var inst = false;
        if (this.props.user.isInstaller == 1)
        {
            inst = true;
        }
        var beak = false;
        if (this.props.user.isBreakdown == 1)
        {
            beak = true;
        }
        var misc = false;
        if (this.props.user.isMisc == 1)
        {
            misc = true;
        }
        var newb = false;
        if (this.props.user.isApprentice == 1)
        {
            newb = true;
        }

        this.state = {
            currentScreen: this.SCREENS.HOME,
            navStack: [],
            subscription: null,
            inst: inst,
            serv: beak,
            misc: misc,
            newb: newb
        };

        this.handleChange = this.handleChange.bind(this);

    }



    submitProfile()
    {
        const formData = new FormData();
        formData.append('isApprentice', this.state.newb ? 1 : 0);
        formData.append('isBreakdown', this.state.serv ? 1 : 0);
        formData.append('isInstaller', this.state.inst ? 1 : 0);
        formData.append('isMisc', this.state.misc ? 1 : 0);

        Axios
            .post(API.user.setProfile, formData)
            .then((res) => {


                if (res.data.success) {

                    var updatedUsr = this.props.user;
                    updatedUsr.isApprentice =  this.state.newb ? 1 : 0;
                    updatedUsr.isBreakdown = this.state.serv ? 1 : 0;
                    updatedUsr.isInstaller = this.state.inst ? 1 : 0;
                    updatedUsr.isMisc =  this.state.misc ? 1 : 0;

                    this.props.userHandler(updatedUsr)
                }

            }).catch(error => {

            });

    }

    getSubInfo()
    {
        Axios
            .get(API.user.getSubscriptionData)
            .then((res) => {
                this.setState({
                    debug: res
                });
                if (res.data.success) {

                    this.setState({
                        subscription: res.data.data

                    })
                }
            })
    }


    componentDidMount()
    {
        this.getSubInfo();
        this.props.showMenuHandler(true);
    }



    handleChange(event) {

        const target = event.target;
        const value = target.checked;
        const name = target.name;

        if (name === "misc" && value)
        {
            this.setState({
                inst:false,
                serv:false,
                misc:true,
                newb:false
            }, () => this.submitProfile());
        } else if (name === "newb" && value)
        {
            this.setState({
                inst:false,
                serv:false,
                misc:false,
                newb:true
            }, () => this.submitProfile());
        } else if (name === "serv" && value)
        {
            this.setState({
                serv:true,
                misc:false,
                newb:false
            }, () => this.submitProfile());
        }else if (name === "inst" && value)
        {
            this.setState({
                inst:true,
                misc:false,
                newb:false
            }, () => this.submitProfile());
        }
        else {
            this.setState({
                [name]: value
            }, () => this.submitProfile());
        }

    }


    handleItemClick (newScreen)
    {
        this.props.showMenuHandler(false);
        const oldScreen = this.state.currentScreen;
        const navStack = this.state.navStack.concat([oldScreen])
        this.setState({
            currentScreen: newScreen,
            navStack: navStack
        });
    }



    handleBackClick ()
    {
        const navStack = this.state.navStack.slice();

        if (navStack.length > 1)
        {
            this.props.showMenuHandler(false);
        } else {
            this.props.showMenuHandler(true);

        }
        const backScreen = navStack[navStack.length - 1];

        this.setState({
            currentScreen: backScreen,
            navStack: navStack.slice(0,navStack.length - 1)
        });
    }

    render(){

        switch (this.state.currentScreen) {


            case this.SCREENS.HOME :


                const email =  <span className="info">{this.props.user.email}</span>

                return(
                    <div className="main-wrap settings-section">
                        <div className="cell small-12 header">
                            <h1 className="head-title toolbox">Account Settings</h1>
                        </div>
                        <div className="cell small-12 medium-4 email">
                            <SectionButton title={["Change Email", email]} sectionClick={() => this.handleItemClick(this.SCREENS.CHANGE_EMAIL)}/>
                        </div>
                        <div className="cell small-12 medium-4 password">
                            <SectionButton title="Change Password" sectionClick={() => this.handleItemClick(this.SCREENS.CHANGE_PASSWORD)}/>
                        </div>
                        <div className="cell small-12 medium-4 profile">
                            <SectionButton title="Profile Picture" sectionClick={() => this.handleItemClick(this.SCREENS.PROFILE_PICTURE)}/>
                        </div>
                        <div className="cell small-12 medium-4">

                            <div className="info-item">

                                <div className="section-title">
                                    <span className="prof-title">Your Profile:</span>

                                    <label className="prof-sel-title">Installations:
                                        <input type="checkbox" name="inst" checked={this.state.inst} onClick={this.handleChange}/>
                                        <span className="checkmark"></span>
                                    </label>

                                    <label className="prof-sel-title">Breakdown &amp; Servicing:
                                        <input type="checkbox" name="serv" checked={this.state.serv} onClick={this.handleChange}/>
                                        <span className="checkmark"></span>
                                    </label>

                                    <label className="prof-sel-title">Miscellaneous:
                                        <input type="checkbox" name="misc" checked={this.state.misc} onClick={this.handleChange}/>
                                        <span className="checkmark"></span>
                                    </label>

                                    <label className="prof-sel-title">Apprentice / Newbie:
                                        <input type="checkbox" name="newb" checked={this.state.newb} onClick={this.handleChange}/>
                                        <span className="checkmark"></span>
                                    </label>
                                </div>

                            </div>

                        </div>
                        <div className="cell small-12 medium-4 terms">
                            <SectionButton title={"Terms & Conditions"} sectionClick={() => this.handleItemClick(this.SCREENS.TNCS)}/>
                        </div>
                        <div className="cell small-12 medium-4 logout">
                            <SectionButton title="Account Signout" sectionClick={this.props.logoutHandler}/>
                        </div>

                    </div>
                );
            case this.SCREENS.CHANGE_EMAIL :
                return(<ChangeEmail backHandler={() => this.handleBackClick()}  />);

            case this.SCREENS.CHANGE_PASSWORD :
                return(<ChangePassword backHandler={() => this.handleBackClick()}  />);

            case this.SCREENS.PROFILE_PICTURE :
                return(<ProfilePicture user={this.props.user} backHandler={() => this.handleBackClick()}  />);
            case this.SCREENS.TNCS :
                return (<Terms backHandler={() => this.handleBackClick()}  />);
            case this.SCREENS.SUBSCRIPTION :
                return (<Subscription backHandler={() => this.handleBackClick()} user={this.props.user} userHandler={this.props.userHandler} />);
            default :
                return(<div>unknown screen</div>);
        }
    }


}

export default SettingsHome;
