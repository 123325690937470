import React from 'react'
import Menu from './Menu'



function NavBar(props) {

    const mainDivClass = props.showMenu ? "top-space" : "top-space smaller-space";



    return(
    	<div className={mainDivClass}>
                <div className="navbar">
                    {(props.subscription !== null && props.subscription.premium === 1) ? <div className="premium-logo"></div> : <div className="logo"></div>}
	            {props.showMenu && <Menu subscription={props.subscription} menuClickHandler={props.menuClickHandler}/>}
	        </div>
        </div>
    );
}

export default NavBar;
