// constants.js

export const kJobsheetType = 1;
export const kDLGSRType = 2;
export const kGasWarningType = 3;
export const kGasInspectionRecordType = 4;
export const kLeisureLGSRType = 5;
export const kLegionellaRAType = 6;
export const kNotToCurrentStandardsType = 7;
export const kServiceSheetType = 8;
export const kHotWaterType = 9;
export const kPowerFlush = 10;
export const kFlushCertificate = 11;

export const kCommercialCateringA = 12;
export const kPlantCommissioningServicingRecord = 13;
export const kNonDomesticInspectionRecord = 14;
export const kGasTestingPurging = 15;
export const kCommercialCateringB = 22;

export const kOilSingleCombustionInspection = 16;
export const kOilStorageSupplyInspection = 17;
export const kOilPressureJetInspection = 18;
export const kOilFiredServicingCommissioningCD11 = 19;
export const kOilWarningNoticeCD14 = 20;
export const kOilStorageRiskAssessment = 21;
export const kOilTankFireRiskAssessment = 23;
export const kOilInstallationComplete = 24;
export const kCommercialBurgerVanSafety = 25;

export const kLetterhead = 26;
