import React from 'react'
function PostImages(props) {

    const images = props.images.map((image, c) => {


        return <img className="image-thmb" src={image.thumb} alt="Thumb" />;

    })



    return(
        <div className="image-gallery" onClick={imgClicked}>{images}</div>

    );



    function imgClicked(e)
    {
        const imgArr = props.images.map((image, c) => {

            return { src: image.thumb };

        })
        props.imageHandler(imgArr)
        e.stopPropagation();
    }

}

export default PostImages;
