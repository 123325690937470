import React, { useEffect, useState } from 'react'
import BackButton from "../common/BackButton";
import NewCustomer from "./NewCustomer";
import Axios from "axios";
import {API} from "../../api/APIClient";
import SimpleAlertDialog, {ModalButton} from "../common/SimpleAlertDialog";



class CustomerList extends React.Component {

    constructor(props) {
        super(props);

        this.SCREENS = {
            HOME: "customerlist",
            NEW_CUSTOMER: "newcustomer"
        };

        this.state = {
            currentScreen: this.SCREENS.HOME,
            navStack: [],
            customers: [],
            loading: true,
            filteredCustomers: [],
            searchText: '',
            alertModalOpen : false,
            alertModalTitle : null,
            alertModalMessage: null,
            alertModalButtons : [],
        }

    }


    componentDidMount() {

        this.getCustomers();
     }





    hideAlertModal() {
        this.setState({
            alertModalOpen : false
        });
    }

    showAlertModal(title, message, buttons) {
        this.setState({
            alertModalOpen : true,
            alertModalTitle : title,
            alertModalMessage : message,
            alertModalButtons : buttons
        });
    }






    selectedCustomer(cust) {
        if (this.props.action === "new_invoice"){
            this.invoiceCustClicked(cust)
        } else if (this.props.action === this.props.screens.CUSTOMERS) {
            this.editCustClicked(cust)
        } else {
            this.props.customerClickHandler(cust)
        }
    }


    getCustomers = () => {

        Axios
            .get(API.office.getCustomers)
            .then((res) => {

                if (res.data.success) {

                    const sortedCustomers = res.data.data.sort((a, b) => {
                        if (a.surname < b.surname) return -1;
                        if (a.surname > b.surname) return 1;
                        return 0;
                    });

                    const customers = sortedCustomers.map((cust, c) => {
                        return <div className="list-item list-arrow" onClick={() => this.selectedCustomer(cust)}>
                            <h4>{cust.firstname} {cust.surname}</h4>
                            <div>{cust.address1} {cust.address2} {cust.town} {cust.county} {cust.postcode}</div>
                        </div>;
                    });
                        this.setState({
                            customers:sortedCustomers,
                            loading : false,
                            filteredCustomers:customers
                    })
                } else {
                    this.setState({
                        customers:[],
                        loading : false,
                        filteredCustomers:[]
                    })
                }
             })
    }

    handleChange = (event) => {
        this.setState({
            searchText: event.target.value
        },() => this.filterCustomers());
    }


    filterCustomers = () => {
        if (this.state.searchText === "") {
            const customers = this.state.customers.map((cust, c) => {
                return <div className="list-item list-arrow" onClick={() => this.selectedCustomer(cust)}>
                    <h4>{cust.firstname} {cust.surname}</h4>
                    <div>{cust.address1} {cust.address2} {cust.town} {cust.county} {cust.postcode}</div>
                </div>;
            });

            this.setState( {
                filteredCustomers: customers
            })
        } else {
            const filteredCustomers = this.state.customers.filter(
                item =>
                    (item.surname && item.surname.toLowerCase().includes(this.state.searchText.toLowerCase())) ||
                    (item.firstname && item.firstname.toLowerCase().includes(this.state.searchText.toLowerCase())) ||
                        (item.telephone && item.telephone.includes(this.state.searchText))||
                                (item.address1 && item.address1.toLowerCase().includes(this.state.searchText.toLowerCase()))


            )

            const sortedCustomers = filteredCustomers.sort((a, b) => {
                if (a.surname < b.surname) return -1;
                if (a.surname > b.surname) return 1;
                return 0;
            });

            const customers = sortedCustomers.map((cust, c) => {
                return <div className="list-item list-arrow" onClick={() => this.selectedCustomer(cust)}>
                    <h4>{cust.firstname} {cust.surname}</h4>
                    <div>{cust.address1} {cust.address2} {cust.town} {cust.county} {cust.postcode}</div>
                </div>;
            });

            this.setState( {
                filteredCustomers: customers
            })
        }

    }


    invoiceCustClicked = (cust) => {
        this.showAlertModal(
            "Select an option",
            "Please select an option from the choices below: ",
            [
                {
                    label : "New invoice ",
                    onClick : () => {
                        this.props.customerClickHandler(cust)
                        this.hideAlertModal() },
                    className : "success"
                },
                {
                    label : "Copy Invoice",
                    className : "success",
                    onClick : () => {
                        cust.invoiceOption = "copy"
                        this.props.customerClickHandler(cust)
                        this.hideAlertModal() },
                },
                {
                    label : "Create from a quote",
                    className : "success",
                    onClick : () => { cust.invoiceOption = "quote"
                        this.props.customerClickHandler(cust)
                        this.hideAlertModal() },
                }
            ]
        )
    }



    editCustClicked = (cust) => {
        const oldScreen = this.state.currentScreen;
        const navStack = this.state.navStack.concat([oldScreen])
        this.setState({
            currentScreen: this.SCREENS.NEW_CUSTOMER,
            navStack: navStack,
            selectedCustomer:cust,
            scrollPosition : window.scrollY
        });
        window.scrollTo(0, 0);

    }


    newCustClicked = () => {
        console.log("newCustClicked")
        const oldScreen = this.state.currentScreen;
        const navStack = this.state.navStack.concat([oldScreen])
        this.setState({
            currentScreen: this.SCREENS.NEW_CUSTOMER,
            navStack: navStack,
            scrollPosition : window.scrollY,
            selectedCustomer:undefined
        });
        window.scrollTo(0, 0);

    }

    handleBackClick ()
    {
        const navStack = this.state.navStack.slice();
        const backScreen = navStack[navStack.length - 1];

        this.setState({
            currentScreen: backScreen,
            navStack: navStack.slice(0,navStack.length - 1)
        }, () => {
            if (navStack.length === 1 && this.state.scrollPosition){
                window.scrollTo(0, this.state.scrollPosition || 0); // Restore scroll position
                this.setState({
                    scrollPosition: undefined
                });
            }});
    }

    reloadAndHandleBackClick ()
    {
        this.handleBackClick();
        this.getCustomers();
    }



    render() {

        switch (this.state.currentScreen) {
            case this.SCREENS.HOME:
                return(

                    <div className="main-wrap">
                        <div className="cell small-12"><BackButton backHandler={this.props.backHandler} /></div>
                        <div className="cell small-12 no-pad">

                            <div className="custlist">

                                <div className="header">
                                    {this.props.action === this.props.screens.CREATE_INVOICE && <h2 className="bold-text">Create an Invoice</h2>}
                                    {this.props.action === this.props.screens.CREATE_QUOTE && <h2 className="bold-text">Create a Quote</h2>}
                                    {this.props.action === this.props.screens.PREV_FORMS && <h2 className="bold-text">View Previous Forms</h2>}
                                    {this.props.action === this.props.screens.CUSTOMERS && <h2 className="bold-text">Manage &amp; add Customers</h2>}
                                    {this.props.action === this.props.screens.PREV_QUOTES && <h2 className="bold-text">View Previous Quotes</h2>}

                                    <span className="bold-text">{this.state.filteredCustomers.length} customers</span>
                                </div>
                                <div className="padit">
                                    Select or Add New Customer
                                </div>
                                <div className="content">

                                    <div className="cell small-12">
                                        <input className="search" type="text" name="searchText" placeholder="Search customers" onChange={this.handleChange} value={this.state.searchText} />
                                    </div>
                                    <div className="cell small-12">
                                        <div onClick={() => this.newCustClicked()} className="ga-button">Add new customer</div>
                                    </div>
                                    <div className="cell small-12">
                                        <span className="bold-text">Select existing Customer</span>
                                    </div>
                                    <div className="cell small-12">

                                        {this.state.loading ? (
                                            <div>Loading...</div>
                                        ) : (
                                            <div>
                                                {this.state.filteredCustomers}
                                            </div>
                                        )}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <SimpleAlertDialog
                            open={this.state.alertModalOpen}
                            title={this.state.alertModalTitle}
                            message={this.state.alertModalMessage}>
                            {
                                this.state.alertModalButtons.map((button) => (
                                    <ModalButton label={button.label} onClick={button.onClick}
                                                 className={button.className}/>
                                ))
                            }
                        </SimpleAlertDialog>

                    </div>
                );

            case this.SCREENS.NEW_CUSTOMER:
                return(<NewCustomer backHandler={() => this.reloadAndHandleBackClick()} customer={this.state.selectedCustomer} />);

        }


    }


}

export default CustomerList;
