import React, {FC, useState} from 'react';
import Axios from "axios";
import {API, APIHelper} from "../../api/APIClient";
import {ValidationUtil} from "../common/ValidationUtil";

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
import { convertToRaw, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import {ReactSortable} from "react-sortablejs";

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../assets/css/websites.css';

import deleteIcon from '../../assets/img/delete.svg';
import {WebsitesUtil} from "../../resources/WebsitesUtil";
import SimpleAlertDialog, {ModalButton} from "../common/SimpleAlertDialog";
import gasSafeLogo from "../../assets/img/gas_safe_icon.png";

const IMAGE_TYPE_HERO = 1;
const IMAGE_TYPE_LOGO = 2;
const IMAGE_TYPE_GALLERY = 3;

class WebsiteEditor extends React.Component {

    constructor(props, context) {
        super(props, context);

        let editorState = EditorState.createWithContent(ContentState.createFromBlockArray(this.getDefaultEditorContent()));

        this.state = {
            website : null,
            submittingOverNetwork : false,
            editorState: editorState,
            logoImage : null,
            defaultBannerImages : [],
            bannerImage : null,
            paymentMethods : [],
            selectedPaymentMethods : [],
            serviceTypes : [],
            selectedServiceTypes : [],
            templates : [],
            selectedTemplateId : -1,
            galleryImages : [],
            removedGalleryImageIds : [],
            useLogo : false,
            submissionError : null,
            submissionWasSuccessful : false,
            modalOpen : false,
            modalTitle : null,
            modalMessage : null,
            modalButtons : [],
            countyAutoCompleteItems : [],
            displayGasSafeLogo : false
        };

        this.logoImageFile = React.createRef(); // Logo Image Upload Input
        this.bannerImageInput = React.createRef(); // Banner Image Upload Input
        this.galleryUploadFile = React.createRef(); // Gallery Image Upload Input

        this.setupForm = this.setupForm.bind(this);
        this.getWebsiteStatusFromNetwork = this.getWebsiteStatusFromNetwork.bind(this);
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.isPaymentMethodSelected = this.isPaymentMethodSelected.bind(this);
        this.getPaymentMethodsFromNetwork = this.getPaymentMethodsFromNetwork.bind(this);
        this.checkPaymentMethod = this.checkPaymentMethod.bind(this);
        this.getServicesFromNetwork = this.getServicesFromNetwork.bind(this);
        this.getTemplatesFromNetwork = this.getTemplatesFromNetwork.bind(this);
        this.getDefaultBannerImagesFromNetwork = this.getDefaultBannerImagesFromNetwork.bind(this);
        this.bannerImageWasSelected = this.bannerImageWasSelected.bind(this);
        this.galleryImageWasSelected = this.galleryImageWasSelected.bind(this);
        this.logoImageWasSelected = this.logoImageWasSelected.bind(this);
        this.removeGalleryImage = this.removeGalleryImage.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.processImageQueue = this.processImageQueue.bind(this);
        this.getWebsiteFromNetwork = this.getWebsiteFromNetwork.bind(this);
        this.populateStateWithWebsite = this.populateStateWithWebsite.bind(this);
        this.setUseLogo = this.setUseLogo.bind(this);
        this.promptToClearAboutMessage = this.promptToClearAboutMessage.bind(this);
        this.clearAboutMessage = this.clearAboutMessage.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.setCountyInputFocus = this.setCountyInputFocus.bind(this);
        this.handleCountyChange = this.handleCountyChange.bind(this);
        this.setAddressCounty = this.setAddressCounty.bind(this);
    }

    componentDidMount() {
        this.setupForm();
    }

    getDefaultEditorContent() {
        let content = "<p>(Insert NAME) is a well-established Plumbing and Heating company based in (Insert TOWN/AREA).</p>"
            + "<p>We are an accredited installer for (Insert Boiler Manufacturer) and registered with Gas Safe. Only Gas Safe registered engineers are allowed, by law, to install or service gas installations - Our Gas Safe Registration Number is - (Insert Gas Safe Number)</p>"
            + "<p>Specialists in the following: -</p>"
            + "<p>Domestic Central Heating Systems, Power Flushing, General Plumbing and Gas Services.</p>"
            + "<p>The company can supply, fit and service all types of domestic gas installations including: -</p>"

            + "<p>"
            + "  <ul>"
            + "       <li>Boilers Fitted and Serviced</li>"
            + "       <li>Full Central Heating Systems Fitted and Serviced</li>"
            + "       <li>Gas Fires Fitted and Serviced</li>"
            + "        <li>Gas Cookers Fitted</li>"
            + "   </ul>"
            + "</p>"

            + "<p>We are also able to provide a full range of domestic plumbing services to suit client’s individual requirements including: -</p>"

            + "<p>"
            + "   <ul>"
            + "       <li>Full Bathroom Installations</li>"
            + "       <li>Showers, Baths and Sinks</li>"
            + "       <li>Kitchen Plumbing Installations</li>"
            + "       <li>Sinks, taps and utility rooms</li>"
            + "       <li>Outside taps and garden taps</li>"
            + "       <li>Washing machines and dishwasher installations</li>"
            + "   </ul>"
            + "</p>"

            + "<p>Be sure to visit our Gallery page for examples of our previous work and the Reviews page to see what our other customers have said about us.</p>"
            + "<p>For full details of all the Gas services provided or to get your quotation please contact us by using the details on the contact page.</p>";

        return convertFromHTML(content);
    }

    setupForm() {
        this.getWebsiteFromNetwork();

        this.getPaymentMethodsFromNetwork();
        this.getServicesFromNetwork();
        this.getTemplatesFromNetwork();
        //this.getDefaultBannerImagesFromNetwork();
    }

    getWebsiteStatusFromNetwork() {
        Axios.get(API.websites.getStatus)
            .then((r) => {
                let allowAccess = false;

                let resp = APIHelper.parse(r);
                if (resp.success) {
                    if (resp.data.status === "active") {
                        allowAccess = true;
                    }
                } else {
                    console.log(resp.error);
                }

                if (!allowAccess) {
                    window.location.href = "/websites/";
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    getWebsiteFromNetwork() {
        Axios.get(API.websites.getWebsite)
            .then((r) => {
                let resp = APIHelper.parse(r);
                if (resp.success) {
                    if (resp.data.website !== null) {
                        console.log("GOT WEBSITE");
                        this.populateStateWithWebsite(resp.data.website);
                    }
                } else {
                    console.log("Error fetching website");
                    console.log(resp.error);
                }
            })
            .catch((e) => {
                console.log("failed to network for website");
                console.log(e);
            })
    }

    getPaymentMethodsFromNetwork() {
        Axios.get(API.websites.getUserWebsiteCardTypes)
            .then((r) => {
                let resp = APIHelper.parse(r);
                if (resp.success) {
                    this.setState({
                        paymentMethods : resp.data.cardTypes
                    });
                } else {
                    console.log(resp.error);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    getServicesFromNetwork() {
        Axios.get(API.websites.getUserWebsiteServiceTypes)
            .then((r) => {
                let resp = APIHelper.parse(r);
                if (resp.success) {
                    this.setState({
                        serviceTypes : resp.data.serviceTypes
                    });
                } else {
                    console.log(resp.error);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    getTemplatesFromNetwork() {
        Axios.get(API.websites.getTemplates)
            .then((r) => {
                let resp = APIHelper.parse(r);
                if (resp.success) {
                    this.setState({
                        templates : resp.data.templates
                    });
                } else {
                    console.log(resp.error);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    getDefaultBannerImagesFromNetwork() {
        Axios.get(API.websites.getUserWebsiteBanners)
            .then((r) => {
                let resp = APIHelper.parse(r);
                if (resp.success) {
                    this.setState({
                        defaultBannerImages : resp.data.banners
                    });
                } else {
                    console.log(resp.error);
                }
            })
            .catch((e) => {
                console.log(e);
            })
    }

    onEditorStateChange(editorState) {
        this.setState({
            editorState
        });
    }

    /**
     * Handler for input items to ensure the value of the input is put into the state
     * @param event     Event from the onChange property
     */
    handleChange(event) {
        const target = event.target;
        const name = target.name;

        let value = target.value;
        if (event.target.type === "checkbox") {
            value = event.target.checked ? 1 : 0
        }

        this.setState({
            [name] : value
        });
    }

    populateStateWithWebsite(website) {
        console.log(website);
        if (website != null) {
            let selectedPaymentMethods = [];
            if (website.hasOwnProperty("acceptedCards")) {
                website.acceptedCards.forEach((item) => {
                    selectedPaymentMethods.push(item.userWebsiteCardTypeId);
                });
            }
            console.log(selectedPaymentMethods);

            let selectedServiceTypes = [];
            if (website.hasOwnProperty("services")) {
                website.services.forEach((item) => {
                    selectedServiceTypes.push(item.userWebsiteServiceTypeId);
                });
            }
            console.log(selectedServiceTypes);

            let logoImage = null;
            let bannerImage = null;
            let galleryImages = [];
            if (website.hasOwnProperty("images")) {
                website.images.forEach((item) => {
                    let imageObj = {
                        id : item.id,
                        isLocal : false,
                        type : item.userWebsiteImageTypeId,
                        imagePath : item.imagePath,
                        thumbPath : item.thumbPath,
                        featured : item.featured == 1,
                        order : item.ordering
                    };

                    if (imageObj.type == IMAGE_TYPE_LOGO) {
                        logoImage = imageObj;
                    } else if (imageObj.type == IMAGE_TYPE_HERO) {
                        bannerImage = imageObj;
                    } else {
                        galleryImages.push(imageObj)
                    }
                });
            }

            let editorState = null;
            if (website.aboutMessage != null) {
                let contentHtml = convertFromHTML(website.aboutMessage);
                editorState = EditorState.createWithContent(ContentState.createFromBlockArray(contentHtml))
            } else {
                editorState = EditorState.createEmpty();
            }

            console.log("SET STATE!");

            let newState = {
                website : website,
                editorState : editorState,
                companyName : website.companyName,
                selectedTemplateId : website.userWebsiteTemplateId,
                galleryBody : website.galleryMessage,
                contactMobile : website.contactMobile,
                contactLandline : website.contactLandline,
                businessAddress1 : website.addressLine1,
                businessAddress2 : website.addressLine2,
                businessAddressCity : website.addressCity,
                businessAddressCounty : website.addressCounty,
                businessAddressPostcode : website.addressPostcode,
                gasSafeNumber : website.gasSafeNumber,
                contactEmail : website.contactEmail,
                selectedPaymentMethods : selectedPaymentMethods,
                selectedServiceTypes : selectedServiceTypes,
                logoImage : logoImage,
                useLogo : website.useLogo == 1,
                bannerImage : bannerImage,
                galleryImages : galleryImages,
                openingHours : website.openingHours,
                displayGasSafeLogo : website.showGasSafeLogo == 1
            };

            console.log(newState);

            this.setState(newState);
        }
    }

    isPaymentMethodSelected(id) {
        for (let i = 0; i < this.state.selectedPaymentMethods.length; i++) {
            if (this.state.selectedPaymentMethods[i] === id) {
                return true;
            }
        }
        return false;
    }

    checkPaymentMethod(id) {
        let paymentMethods = this.state.selectedPaymentMethods;

        if (this.isPaymentMethodSelected(id)) {
            for (let i = 0; i < paymentMethods.length; i++) {
                if (paymentMethods[i] == id) {
                    paymentMethods.splice(i, 1);
                    break;
                }
            }
        } else {
            paymentMethods.push(id);
        }

        this.setState({
            selectedPaymentMethods : paymentMethods
        });
    }

    isServiceTypeSelected(id) {
        for (let i = 0; i < this.state.selectedServiceTypes.length; i++) {
            if (this.state.selectedServiceTypes[i] === id) {
                return true;
            }
        }
        return false;
    }

    checkServiceType(id) {
        let serviceTypes = this.state.selectedServiceTypes;

        let position = -1;

        for (let i = 0; i < serviceTypes.length; i++) {
            if (serviceTypes[i] === id) {
                position = i;
                break;
            }
        }

        if (position !== -1) {
            serviceTypes.splice(position, 1);
        } else {
            serviceTypes.push(id);
        }

        this.setState({
            selectedServiceTypes : serviceTypes
        });
    }

    bannerImageWasSelected(event) {
        let input = event.target;

        if (input.files.length > 0) {
            let file = input.files[0];

            let reader = new FileReader();
            reader.onload = () => {
                this.setState({
                    bannerImage: {
                        isLocal: true,
                        imagePath: reader.result,
                        file: file
                    }
                });
            }
            reader.readAsDataURL(file);

            input.value = null;
        }
    }

    logoImageWasSelected(event) {
        let input = event.target;

        if (input.files.length > 0) {
            let file = input.files[0];

            let reader = new FileReader();
            reader.onload = () => {
                this.setState({
                    logoImage: {
                        isLocal: true,
                        imagePath: reader.result,
                        file: file
                    }
                });
            }
            reader.readAsDataURL(file);

            input.value = null;
        }
    }

    galleryImageWasSelected(event) {
        let input = event.target;

        if (input.files.length > 0) {
            let file = input.files[0];

            let galleryImages = this.state.galleryImages;

            let reader = new FileReader();
            reader.onload = () => {
                galleryImages.push({
                    id : Date.now() *  -1,
                    isLocal : true,
                    imagePath : reader.result,
                    file : file,
                    featured : (this.state.galleryImages.length === 0)
                });

                this.setState({
                    galleryImages : galleryImages
                });
            };
            reader.readAsDataURL(file);

            // Clear input
            input.value = null;
        }
    }

    setGalleryFeatured(checked, index) {
        let galleryImages = this.state.galleryImages;
        if (checked) {
            for (let i = 0; i < galleryImages.length; i++) {
                galleryImages[i].featured = false;
            }

            galleryImages[index].featured = true;
        }

        this.setState({
            galleryImages: galleryImages
        });
    }

    removeGalleryImage(index) {
        let galleryImages = this.state.galleryImages;
        let removedGalleryImageIds = this.state.removedGalleryImageIds;
        if (galleryImages.length > index) {
            let removedItem = galleryImages.splice(index, 1)[0];

            // Check to see if the item removed was featured, if so nominate a new
            // image as "featured" instead.
            if (removedItem.featured && galleryImages.length > 0) {
                galleryImages[0].featured = true;
            }

            // If the item is from the server, add this to the list of removed images
            // that we need to tell the server upon upload.
            if (!removedItem.isLocal) {
                removedGalleryImageIds.push(removedItem.id);
            }
        }

        console.log(removedGalleryImageIds);

        this.setState({
            galleryImages : galleryImages,
            removedGalleryImageIds : removedGalleryImageIds
        });
    }


    goWebsites() {
        window.location.href = '/website/';
    }
    submitForm() {
        let validationResult = ValidationUtil.validateCreateFormData(this.state, [
            {key : "companyName", type : "string", label : "Company Name"},
            {key : "selectedTemplateId", type : "int", label : "Website Template", postName : "userWebsiteTemplateId"},
            {key : "contactMobile", type : "string", label : "Contact Mobile", optional : true },
            {key : "contactLandline", type : "string", label : "Contact Landline", optional : true},
            {key : "contactEmail", type : "string", label : "Email Address", optional : true},
            {key : "gasSafeNumber", type : "string", label : "Gas Safe Number", optional : true},
            {key : "businessAddress1", type : "string", label : "Business Address Line 1", postName : "addressLine1", optional : true},
            {key : "businessAddress2", type : "string", label : "Business Address Line 2", postName : "addressLine2", optional : true},
            {key : "businessAddressCity", type : "string", label : "Business Address City", postName : "addressCity", optional : true},
            {key : "businessAddressCounty", type : "string", label : "Business Address County", postName : "addressCounty", optional : true},
            {key : "businessAddressPostcode", type : "string", label : "Business Address Postcode", postName : "addressPostcode", optional : true},
            {key : "galleryBody", type : "string", label : "Gallery Blurb", postName : "galleryMessage" },
            {key : "openingHours", type : "string", label : "Opening Hours", optional : true}
        ]);

        if (validationResult.success) {
            let formData = validationResult.formData;

            let showGasSafeLogo = this.state.displayGasSafeLogo ? 1 : 0;
            formData.append("showGasSafeLogo", showGasSafeLogo);

            // Append About Message
            let rawContentState = convertToRaw(this.state.editorState.getCurrentContent());
            let contentHtml = draftToHtml(
                rawContentState,
                undefined,
                undefined,
                undefined
            );
            formData.append("aboutMessage", contentHtml);

            formData.append("useLogo", (this.state.useLogo ? 1 : 0));

            // Add checkbox selections
            formData.append("services", JSON.stringify(this.state.selectedServiceTypes));
            formData.append("cards", JSON.stringify(this.state.selectedPaymentMethods));

            // Collate Images
            let uploadImages = [];
            let updateImages = [];

            if (this.state.logoImage != null) {
                if (this.state.logoImage.isLocal) {
                    console.log("Has local Logo Image");
                    uploadImages.push({
                        type: IMAGE_TYPE_LOGO,
                        file: this.state.logoImage.file
                    });
                }
            }

            if (this.state.bannerImage != null) {
                if (this.state.bannerImage.isLocal) {
                    uploadImages.push({
                        type : IMAGE_TYPE_HERO,
                        file : this.state.bannerImage.file
                    });
                }
            }

            if (this.state.galleryImages.length > 0) {
                for (let i = 0; i < this.state.galleryImages.length; i++) {
                    let image = this.state.galleryImages[i];
                    if (image.isLocal) {
                        console.log("Has local Gallery Image");
                        // If it's a local file, prepare for upload
                        uploadImages.push({
                            type : IMAGE_TYPE_GALLERY,
                            file : image.file,
                            order : i,
                            featured : image.featured ? 1 : 0
                        });
                    } else {
                        // Otherwise, note the position for the ID
                        updateImages.push({
                            id : image.id,
                            featured : image.featured ? 1 : 0,
                            order : i
                        });
                    }
                }
            }

            formData.append("images", JSON.stringify(updateImages));
            formData.append("removedImages", JSON.stringify(this.state.removedGalleryImageIds));

            console.log("UPLOAD IMAGES: " + uploadImages.length);
            console.log(uploadImages);

            if (this.state.website != null) {
                formData.append("id", this.state.website.id);
            }

            this.setState({
                submissionWasSuccess : false,
                submissionError : null,
                submittingOverNetwork : true,
                uploadProgressPercent : 0
            });

            Axios.post(API.websites.submitUserWebsite, formData)
                .then((r) => {
                    let resp = APIHelper.parse(r);
                    if (resp.success) {
                        this.processImageQueue(resp.data.website, uploadImages, 0);
                    } else {
                        this.state({
                            submittingOverNetwork : false,
                            submissionError : resp.error
                        });
                        //alert(resp.error);
                    }
                })
                .catch((e) => {
                    this.setState({
                        submittingOverNetwork : false,
                        submissionError : "An unknown error has occurred. Please reload the page."
                    });

                    console.log(e);
                });
        } else {
            alert(validationResult.error);
        }
    }

    processImageQueue(website, imageArray, index) {
        if (imageArray.length > index) {
            let image = imageArray[index];

            let formData = new FormData();
            formData.append("userWebsiteId", website.id);
            formData.append("image", image.file);

            if (image.hasOwnProperty("type")) {
                formData.append("type", image.type);
            }

            if (image.hasOwnProperty("order")) {
                formData.append("order", image.order);
            }

            if (image.hasOwnProperty("featured")) {
                formData.append("featured", image.featured ? "1" : "0");
            }

            Axios.post(API.websites.submitWebsiteImage, formData, {
                onUploadProgress : (data) => {
                    if (data.lengthComputable) {
                        var percent = ((data.loaded / data.total) * 100);

                        console.log("UPLOAD: " + percent + "%");
                        this.setState({
                            uploadProgressPercent : percent
                        });
                    }
                }
            })
            .then((r) => {
                let resp = APIHelper.parse(r);
                if (resp.success) {
                    if (resp.data.website !== undefined) {
                        website = resp.data.website;
                    }
                }

                imageArray[index].uploadSuccess = resp.success;

                // Move onto next image in queue
                this.processImageQueue(website, imageArray, index + 1);
            })
            .catch((e) => {
                console.log(e);

                // Shrug...?
                imageArray[index].uploadSuccess = false;
                this.processImageQueue(website, imageArray, index + 1);
            });
        } else {
            this.populateStateWithWebsite(website);
            this.setState({
                uploadProgressPercent : 0,
                submittingOverNetwork : false,
                submissionWasSuccess : true
            });
        }
    }

    setUseLogo(value) {
        this.setState({
            useLogo : value
        });
    }

    getOrDefault(input, defaultValue) {
        if (input != null) {
            return input;
        }
        return defaultValue;
    }

    promptToClearAboutMessage() {
        this.showModal("Please confirm", "Are you sure you want to reset the About message? All currently input text will be deleted.", [
            { label : "Delete", className : "danger", onClick : () => { this.clearAboutMessage() }},
            { label : "Cancel", onClick : () => { this.hideModal() } }
        ]);
    }

    clearAboutMessage() {
        this.hideModal();
        this.setState({
            editorState : EditorState.createEmpty()
        });
    }

    showModal(title, message, buttons) {
        if (buttons === undefined) {
            buttons = [
                { label : "OK", onClick: () => this.hideModal() }
            ]
        }

        this.setState({
            modalOpen : true,
            modalTitle : title,
            modalMessage : message,
            modalButtons : buttons
        });
    }

    hideModal() {
        this.setState({
            modalOpen : false
        });
    }

    setCountyInputFocus(focus) {
        setTimeout(() => {
            this.setState({
                countyInputFocus : focus
            });
        }, 100);
    }

    handleCountyChange(event) {
        let value = "";
        if (event.target != null) {
            value = event.target.value;
        }

        this.handleChange(event);

        if (this.countyAutofillTimeout !== undefined) {
            clearTimeout(this.countyAutofillTimeout);
        }

        if (value !== "") {
            this.countyAutofillTimeout = setTimeout(() => {
                let formData = new FormData();
                formData.append("keyword", value);

                Axios.post(API.websites.autofillCounty, formData)
                    .then((r) => {
                        let resp = APIHelper.parse(r);
                        if (resp.success) {
                            this.setState({
                                countyAutoCompleteItems: resp.data.counties
                            });
                        } else {
                            console.log(resp.error);
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }, 700);
        } else {
            this.setState({
                countyAutoCompleteItems : []
            });
        }
    }

    setAddressCounty(id, county) {
        console.log(id + " :: " + county);
        this.setState({
            businessAddressCounty : county
        });
    }

    render() {
        const { editorState } = this.state;

        let templateElements = [];
        if (this.state.templates.length > 0) {
            for (let i = 0; i < this.state.templates.length; i++) {
                let template = this.state.templates[i];

                let element = (
                    <label className={"item text-center"}>
                        <div><span className={"square-image"} style={{"backgroundImage" : "url(" + template.iconPath + ")"}} /></div>
                        <div className={"consistent-title"}>{template.name}</div>
                        <div><input type={"checkbox"} checked={template.id === this.state.selectedTemplateId} onChange={() => this.setState({ selectedTemplateId : template.id })} /></div>
                    </label>
                );

                templateElements.push(element);
            }
        }

        let logoUploadElement = [];
        if (this.state.useLogo) {
            logoUploadElement = (
                <div className={"form-block narrow"}>
                    <label>Logo</label>
                    <small>Upload your company logo to display in the header</small>

                    <div className={"image-selector"}>
                        <div className={"preview-container"}>
                            <div className={"preview-image contain"} style={{"backgroundImage" : "url(" + (this.state.logoImage !== null ? this.state.logoImage.imagePath : "") + ")"}} />
                        </div>
                        <div className={"selections"}>
                            <div className={"image-upload"}>
                                <label>Upload your image</label>
                                <span className={"ga-button"} onClick={() => this.logoImageFile.current.click() }>Pick image</span>
                                <small>Maximum file size 2MB. Larger images are encouraged for best results. Please ensure you have the rights to upload an image.</small>

                                <div className={"file-hide"}>
                                    <input type={"file"} onChange={this.logoImageWasSelected} ref={this.logoImageFile} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        let cardElements = [];
        if (this.state.paymentMethods.length > 0) {
            for (let i = 0; i < this.state.paymentMethods.length; i++) {
                let paymentMethod = this.state.paymentMethods[i];

                let checked = this.isPaymentMethodSelected(paymentMethod.id);

                let element = (
                    <label className={"item text-center"}>
                        <div><img src={paymentMethod.iconImagePath} /></div>
                        <div>{paymentMethod.name}</div>
                        <div><input type={"checkbox"} checked={checked} onChange={()=>this.checkPaymentMethod(paymentMethod.id)} /></div>
                    </label>
                );

                cardElements.push(element);
            }
        }

        let serviceElements = [];
        if (this.state.serviceTypes.length > 0) {
            for (let i = 0; i < this.state.serviceTypes.length; i++) {
                let serviceType = this.state.serviceTypes[i];

                let checked = this.isServiceTypeSelected(serviceType.id);

                let element = (
                    <label className={"item text-center"}>
                        <div><span className={"square-image"} style={{"backgroundImage" : "url(" + serviceType.iconPath + ")"}} /></div>
                        <div className={"consistent-title"}>{serviceType.title}</div>
                        <div><input type={"checkbox"} checked={checked} onChange={() => this.checkServiceType(serviceType.id)} /></div>
                    </label>
                );

                serviceElements.push(element);
            }
        }

        let sortableGallery = (
            <div className={"empty-message"}>No images, add some using the button below.</div>
        );
        if (this.state.galleryImages.length > 0) {
            /*<label className={"action"}>
                                <input type={"checkbox"} checked={item.featured} onChange={(e) => { this.setGalleryFeatured(e.target.checked, index) }} />
                                <span className={"star"}>&#9733;</span>
                            </label>*/
            sortableGallery = (
                <ReactSortable list={this.state.galleryImages} setList={newState => this.setState({ galleryImages : newState })}>
                    {this.state.galleryImages.map((item, index) => (
                        <div className={"image"} key={item.id} style={{"backgroundImage" : "url(" + item.imagePath + ")" }}>
                            <span className={"remove"}>
                                <span className={"circle"} style={{"backgroundImage" : "url(" + deleteIcon + ")"}} onClick={() => this.removeGalleryImage(index)} />
                            </span>
                        </div>
                    ))}
                </ReactSortable>
            );
        }

        let submitProgress = (
            <div className={"text-center"}>
                <div><span className={"ga-button"} onClick={this.submitForm}>{ this.state.website == null ? "Make My Website" : "Save Changes" }</span></div>
                <div style={{marginTop : "40px"}}><span onClick={this.goWebsites} className={"ga-button"} >Return to Website Manager</span></div>
            </div>
        );
        if (this.state.submittingOverNetwork) {
            submitProgress = (
                <div className={"text-center"}>
                    <span>Please wait, uploading... {this.state.uploadProgressPercent + "%"}</span>
                    <div className={"progress-bar"}>
                        <div className={"progress"} style={{"width" : this.state.uploadProgressPercent + "%"}} />
                    </div>
                </div>
            );
        }

        let feedbackMessage = [];
        if (this.state.submissionWasSuccess) {
            feedbackMessage = (
                <div className={"feedback-message success"}>
                    Your website has been successfully saved. <a href={WebsitesUtil.getWebsiteUrl(this.state.website.vanityName)} target={"_blank"}>Click here to view your website</a>.
                </div>
            )
        } else if (this.state.submissionError != null) {
            feedbackMessage = (
                <div className={"feedback-message error"} onClick={() => this.setState({submissionError : null})}>
                    {this.state.submissionError}
                </div>
            )
        }

        let countyAutofill = [];
        if (this.state.countyInputFocus) {
            if (this.state.countyAutoCompleteItems != null && this.state.countyAutoCompleteItems.length > 0) {
                countyAutofill = (
                    <div className={"autocomplete-menu"}>
                        {
                            this.state.countyAutoCompleteItems.map((item) => (
                                <div className={"autocomplete-item"} key={item.id} onClick={() => this.setAddressCounty(item.id, item.county)}>
                                    {item.county}
                                </div>
                            ))
                        }
                    </div>
                )
            }
        }

        /*
        <div className={"selection-row"}>
            <div className={"selection-image active"} />
            <div className={"selection-image"} />
            <div className={"selection-image"} />
        </div>
        <div className={"selection-row"}>
            <div className={"selection-image"} />
            <div className={"selection-image"} />
            <div className={"selection-image"} />
        </div>

        <div className={"or-label"}>
            <div className={"line"} />
            <div className={"text"}>or</div>
            <div className={"line"} />
        </div>
         */

        return(<div>
            <div className="main-wrap">
                <div className="cell small-12 header">
                    <h1 className="head-title websites">Website Creator</h1>
                </div>

                <div className={"content-body"}>
                    <div className={"form-block narrow"}>
                        <h2>Homepage</h2>
                    </div>

                    <div className={"form-block narrow"}>
                        <label>Company Name</label>
                        <input type={"text"} name={"companyName"} onChange={this.handleChange} className={"form-control"} placeholder={"Company Name"} value={this.state.companyName} />
                    </div>

                    <div className={"form-block narrow"}>
                        <label>Theme</label>
                        <small>Select a theme for your website. You can change your website's theme at any time.</small>

                        <div className={"square-selections"}>
                            <div className={"selection-row"}>
                                {templateElements}
                            </div>
                        </div>
                    </div>

                    <div className={"form-block narrow"}>
                        <label>Header Style</label>
                        <small>You can display either your Company's name in the website header or display your Company's branding logo. If you are uncertain which to display, please select "Display Company Name". <strong>Recommended image size of 500 pixels by 200 pixels.</strong></small>

                        <div className={"content-block"}>
                            <div className={"equal-block text-center"}>
                                <label><input type={"checkbox"} checked={!this.state.useLogo} onChange={(e) => { if (e.target.checked) { this.setUseLogo(false); } }} /> Display Company Name</label>
                            </div>
                            <div className={"equal-block text-center"}>
                                <label><input type={"checkbox"} checked={this.state.useLogo} onChange={(e) => { if (e.target.checked) { this.setUseLogo(true); } }} /> Display Company Logo</label>
                            </div>
                        </div>
                    </div>

                    {logoUploadElement}

                    <div className={"form-block narrow"}>
                        <label>Background Image</label>
                        <small>Display an image behind your company name or logo.</small>

                        <div className={"image-selector"}>
                            <div className={"preview-container"}>
                                <div className={"preview-image"} style={{"backgroundImage" : "url(" + (this.state.bannerImage !== null ? this.state.bannerImage.imagePath : "") + ")"}} />
                            </div>
                            <div className={"selections"}>
                                <div className={"image-upload"}>
                                    <label>Upload your own image</label>
                                    <span className={"ga-button"} onClick={() => this.bannerImageInput.current.click() }>Pick image</span>
                                    <small>Maximum file size 2MB. Larger images are encouraged for best results. Please ensure you have the rights to upload an image.</small>

                                    <div className={"file-hide"}>
                                        <input type={"file"} onChange={this.bannerImageWasSelected} ref={this.bannerImageInput} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"form-block narrow"}>
                        <label>About</label>
                        <small>Please provide a description about your Company you can either edit the pre populate description or add your own wording!<br /><br />You may want to talk about how long you have been in business, what you or your company specialises in and any niche speciality services that your company can offer potential customers.</small>

                        <Editor
                            editorState={editorState}
                            toolbarClassName={"editor-toolbar"}
                            editorClassName={"editor-body"}
                            onEditorStateChange={this.onEditorStateChange}
                            toolbar={{
                                options : ["inline", "textAlign", "list", "history"],
                                list: {
                                    options: ['unordered', 'ordered']
                                }
                            }}
                        />

                        <div className={"text-right"}>
                            <span className={"ga-button"} onClick={this.promptToClearAboutMessage}>Clear About Text</span>
                        </div>
                    </div>

                    <div className={"form-block narrow"}>
                        <div className={"content-block"}>
                            <div className={"equal-block text-left"}>
                                <label>Contact Mobile</label>
                                <small>Your business mobile contact number</small>

                                <input type={"text"} className={"form-control"} name={"contactMobile"} onChange={this.handleChange} value={this.getOrDefault(this.state.contactMobile, "")} />
                            </div>

                            <div className={"equal-block text-left"}>
                                <label>Contact Landline</label>
                                <small>Your business landline contact number</small>
                                <input type={"text"} className={"form-control"} name={"contactLandline"} onChange={this.handleChange} value={this.getOrDefault(this.state.contactLandline, "")} />
                            </div>
                        </div>

                        <div className={"content-block text-left"}>
                            <div className={"equal-block"}>
                                <label>Contact Email</label>
                                <small>Your business email address</small>
                                <input type={"text"} className={"form-control"} name={"contactEmail"} onChange={this.handleChange} value={this.getOrDefault(this.state.contactEmail, "")} />
                            </div>

                            <div className={"equal-block text-left"}>
                                <label>Gas Safe Number</label>
                                <small>Your Gas Safe Number</small>
                                <input type={"text"} className={"form-control"} name={"gasSafeNumber"} onChange={this.handleChange} value={this.getOrDefault(this.state.gasSafeNumber, "")} />
                            </div>
                        </div>

                        <div className={"content-block text-left"}>
                            <div className={"equal-block"} />
                            <div className={"equal-block"}>
                                <div className={"section-with-icon"}>
                                    <div className={"icon"}>
                                        <img src={gasSafeLogo} className={"default-icon"} alt={"Gas Safe Logo"} />
                                    </div>
                                    <div className={"content"}>
                                        <label><input type={"checkbox"} name={"displayGasSafeLogo"} onChange={this.handleChange} checked={this.state.displayGasSafeLogo} /> Show Gas Safe Logo</label>
                                        <small>By ticking you are confirming you have all permissions to use the Gas Safe logo on your business website.</small>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"content-block text-left"}>
                            <div className={"equal-block"}>
                                <label>Opening Hours</label>
                                <small>Let customers know when you are open or available to take jobs. e.g. Mon-Fri 09:00 - 17:00</small>
                                <input type={"text"} className={"form-control"} name={"openingHours"} onChange={this.handleChange} value={this.getOrDefault(this.state.openingHours, "")} />
                            </div>

                            <div className={"equal-block text-left"}></div>
                        </div>

                        <div className={"content-block text-left"}>
                            <div className={"equal-block"}>
                                <label>Business Address Line 1</label>
                                <input type={"text"} className={"form-control"} name={"businessAddress1"} onChange={this.handleChange} value={this.getOrDefault(this.state.businessAddress1, "")} />
                            </div>

                            <div className={"equal-block text-left"}>
                                <label>Business Address Line 2</label>
                                <input type={"text"} className={"form-control"} name={"businessAddress2"} onChange={this.handleChange} value={this.getOrDefault(this.state.businessAddress2, "")} />
                            </div>
                        </div>

                        <div className={"content-block text-left"}>
                            <div className={"equal-block"}>
                                <label>Business Address City</label>
                                <input type={"text"} className={"form-control"} name={"businessAddressCity"} onChange={this.handleChange} value={this.getOrDefault(this.state.businessAddressCity, "")} />
                            </div>

                            <div className={"equal-block text-left autocomplete-container"}>
                                <label>Business Address County</label>
                                <input type={"text"} className={"form-control"} name={"businessAddressCounty"} onChange={this.handleCountyChange} value={this.getOrDefault(this.state.businessAddressCounty, "")} onFocus={() => this.setCountyInputFocus(true)} onBlur={() => this.setCountyInputFocus(false)} autoComplete={"off"} />
                                { countyAutofill }
                            </div>
                        </div>

                        <div className={"content-block text-left"}>
                            <div className={"equal-block"}>
                                <label>Business Address Postcode</label>
                                <input type={"text"} className={"form-control"} name={"businessAddressPostcode"} onChange={this.handleChange} value={this.getOrDefault(this.state.businessAddressPostcode, "")} />
                            </div>

                            <div className={"equal-block text-left"} />
                        </div>
                    </div>

                    <div className={"form-block narrow"}>
                        <label>Accepted Payment Methods</label>
                        <small>Please select all Payment Methods that you or your business accepts. Please do not select any of the payment methods that are not applicable!</small>

                        <div className={"square-selections"}>
                            <div className={"selection-row"}>
                                {cardElements}
                            </div>
                        </div>
                    </div>

                    <div className={"form-block narrow"}>
                        <label>Services Offered</label>
                        <small>Please select any Services that you or your business offers.</small>

                        <div className={"square-selections"}>
                            <div className={"selection-row"}>
                                {serviceElements}
                            </div>
                        </div>
                    </div>

                    <div className={"form-block narrow"}>
                        <h2>Gallery</h2>
                    </div>

                    <div className={"form-block narrow"}>
                        <label>Gallery Blurb</label>
                        <small>This message will be displayed at the top of your Gallery page. You may use this to explain a little bit about the images you are presenting.</small>

                        <textarea className={"form-control"} name={"galleryBody"} onChange={this.handleChange} value={this.state.galleryBody} />
                    </div>

                    <div className={"form-block narrow"}>
                        <label>Gallery Images</label>
                        <small>Upload images of your work, your self or your employees to entice customers. Images added here can be selected as your "banner" image, which will be displayed behind your Company Name/Company Logo in the header of your website. Drag images around to re-order them to your liking.</small>

                        <div className={"image-dragger"}>
                            {sortableGallery}

                            <div className={"text-center"}>
                                <div className={"file-hide"}>
                                    <input type={"file"} name={"galleryUploadTemp"} onChange={this.galleryImageWasSelected} ref={this.galleryUploadFile} />
                                </div>
                                <span className={"ga-button"} onClick={() => this.galleryUploadFile.current.click() }>Select Image</span>
                                <small className={"upload-info"}>Maximum file size 2MB. Larger images are encouraged for best results. Please ensure you have the rights to upload an image.</small>
                            </div>
                        </div>
                    </div>

                    <div className={"form-block narrow"}>
                        {feedbackMessage}

                        {submitProgress}
                    </div>
                </div>
            </div>

            <SimpleAlertDialog
                open={this.state.modalOpen}
                title={this.state.modalTitle}
                message={this.state.modalMessage}>
                {
                    this.state.modalButtons.map((item) => (
                        <ModalButton label={item.label} className={item.className} onClick={item.onClick} />
                    ))
                }
            </SimpleAlertDialog>
        </div>);
    }

    /*
    <small className={"feature-info"}>Mark an image with <span className={"star"}>&#9733;</span> to make it your Banner Image.</small>
     */

}

export default WebsiteEditor;