import React from 'react'
import BackButton from "../common/BackButton";
import Axios from "axios";
import {API} from "../../api/APIClient";
import {
    kJobsheetType,
    kDLGSRType,
    kGasWarningType,
    kGasInspectionRecordType,
    kLeisureLGSRType,
    kLegionellaRAType,
    kNotToCurrentStandardsType,
    kServiceSheetType,
    kHotWaterType,
    kPowerFlush,
    kFlushCertificate,
    kCommercialCateringA,
    kPlantCommissioningServicingRecord,
    kNonDomesticInspectionRecord,
    kGasTestingPurging,
    kCommercialCateringB,
    kOilSingleCombustionInspection,
    kOilStorageSupplyInspection,
    kOilPressureJetInspection,
    kOilFiredServicingCommissioningCD11,
    kOilWarningNoticeCD14,
    kOilStorageRiskAssessment,
    kOilTankFireRiskAssessment,
    kOilInstallationComplete,
    kCommercialBurgerVanSafety,
    kLetterhead
} from '../../resources/constants';
import { format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';


class FormsList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            forms: [],
            loading: true
        }

    }


    componentDidMount() {

        this.getForms();
    }



    viewForm = (form) => {
        Axios.get(API.office.getFormPDF + form.formId, {
            responseType: "blob",
        })
            .then((res) => {
                const pdfBlob = new Blob([res.data], { type: "application/pdf" });
                const pdfUrl = URL.createObjectURL(pdfBlob);
                this.setState({
                    pdfUrl: pdfUrl
                });
            })
            .catch((error) => {
                console.error("Error fetching PDF:", error);
            });
    };

    getFormTitle = (formTypeId) => {

         switch (formTypeId) {
            case kJobsheetType:
                return "Jobsheet";
            case kDLGSRType:
                return "Landlord Gas Safety Record";
            case kGasWarningType:
                return "Gas Warning / Advice Notice";
            case kGasInspectionRecordType:
                return "Gas Inspection Record";
            case kLeisureLGSRType:
                return "Leisure Industry GSR";
            case kLegionellaRAType:
                return "Legionella Risk Assessment";
            case kNotToCurrentStandardsType:
                return "Not to Current Standards";
            case kServiceSheetType:
                return "Maintenance Service Record";
            case kHotWaterType:
                return "Hot Water Cylinder Checklist";
            case kPowerFlush:
                return "Powerflushing Checklist";
            case kFlushCertificate:
                return "System Flushing Certificate";
            case kCommercialCateringA:
                return "Commercial Catering A";
            case kCommercialCateringB:
                return "Commercial Catering B";
            case kGasTestingPurging:
                return "Gas Testing/Purging";
            case kNonDomesticInspectionRecord:
                return "Non-Domestic Inspection Record";
            case kPlantCommissioningServicingRecord:
                return "Plant Commissioning/Servicing Record";
            case kLetterhead:
                return "Letterhead";
            case kOilSingleCombustionInspection:
                return "Single Combustion Appliance Service";
            case kOilPressureJetInspection:
                return "Liquid Fuel Jet Appliance Service";
            case kOilStorageSupplyInspection:
                return "Fuel Storage & Supply";
            case kOilFiredServicingCommissioningCD11:
                return "CD/11 Oil Firing Servicing";
            case kOilWarningNoticeCD14:
                return "CD/14 Warning & Advice Notice";
            case kOilStorageRiskAssessment:
                return "TI/133ND Oil Storage Risk Assessment";
            case kOilTankFireRiskAssessment:
                return "Oil Tank Spillage RA";
            case kOilInstallationComplete:
                return "Oil Firing Installation Completion";
            case kCommercialBurgerVanSafety:
                return "Mobile Catering Vehicle Service Record";
            default:
                return ""

        }
    }


    groupFormsByMonth(forms) {
        const grouped = forms.reduce((acc, form) => {
            const date = parseISO(form.dateCreated);
            const monthYear = format(date, 'MMMM yyyy');

            if (!acc[monthYear]) {
                acc[monthYear] = { forms: [], isOpen: false };
            }
            acc[monthYear].forms.push(form);
            return acc;
        }, {});
        return grouped;
    }


    renderForms() {
        const { forms } = this.state;

        return Object.keys(forms).sort((a, b) => new Date(b) - new Date(a)).map((monthYear) => (
            <div key={monthYear}>
                {forms[monthYear].isOpen ?
                    <h3 className="formHead"
                        onClick={() => this.toggleMonth(monthYear)}>{monthYear}
                        <div
                            style={{
                                transition: "transform 200ms linear",
                                transform: "rotate(90deg)",
                                display: "inline-block",
                                float: "right"
                            }}>
                            {">"}
                        </div>
                    </h3>
                    :
                    <h3 className="formHead"
                        onClick={() => this.toggleMonth(monthYear)}>{monthYear}
                        <div
                            style={{
                                display: "inline-block",
                                float: "right"
                            }}>
                            {">"}
                        </div>
                    </h3>
                }

                {forms[monthYear].isOpen && forms[monthYear].forms.map((form, index) => (
                    <div className="list-item list-arrow" onClick={() => this.viewForm(form)} key={index}>
                        <h4>{this.getFormTitle(form.formTypeId)}</h4>
                        <div>{format(parseISO(form.dateCreated), 'dd/MM/yyyy')}</div>
                    </div>
                ))}
            </div>
        ));
    }

    toggleMonth = (monthYear) => {
        this.setState((prevState) => ({
            forms: {
                ...prevState.forms,
                [monthYear]: {
                    ...prevState.forms[monthYear],
                    isOpen: !prevState.forms[monthYear].isOpen
                }
            }
        }));
    };


    getForms = () => {

        const formData = new FormData();
        formData.append('customerID',this.props.customer.customerID);

        Axios
            .post(API.office.getCustomerForms, formData)
            .then((res) => {

                if (res.data.success) {
                    const groupedForms = this.groupFormsByMonth(res.data.data);


                    this.setState({
                        forms:groupedForms,
                        loading : false
                    })
                } else {
                    this.setState({
                        forms:[],
                        loading : false
                    })
                }
            }).catch(error => {


            console.log(error.response)

        });


    }


    closePDF() {

        this.setState({
            pdfUrl: undefined
        });
    }


    render() {

        if (this.state.pdfUrl) {

            return(

                <div className="main-wrap">
                    <div className="cell small-12"><div  onClick={() => this.closePDF()} className="ga-button ga-back">
                        Back
                    </div></div>
                    <div className="cell small-12 no-pad">

                        <div className="formlist">


                            <div className="content">


                                <div className="cell small-12">


                                                    <div style={{ width: "100%", height: "80vh", marginTop: "20px" }}>
                                                        <iframe
                                                            src={this.state.pdfUrl}
                                                            style={{ width: "100%", height: "100%", border: "none" }}
                                                        ></iframe>
                                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            );
        } else {

            return(

                <div className="main-wrap">
                    <div className="cell small-12"><BackButton backHandler={this.props.backHandler} /></div>
                    <div className="cell small-12 no-pad">

                        <div className="formlist">

                            <div className="header">
                                <span className="bold-text">View Previous Forms</span>
                            </div>
                            <div className="padit">
                                Select a Form
                            </div>
                            <div className="content">


                                <div className="cell small-12">

                                    {this.state.loading ? (
                                        <div>Loading...</div>
                                    ) : (

                                        <div>

                                                        {this.renderForms()}

                                        </div>

                                    )}
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            );
        }


    }


}

export default FormsList;
