import React from 'react'
import BackButton from "../common/BackButton";
import Axios from "axios";
import {API} from "../../api/APIClient";
import { format } from 'date-fns';
import { formatCurrency } from  "../../resources/Formatters";


class InvoiceList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            invoices: [],
            loading: true
        }

    }


    componentDidMount() {

        this.getInvoices();
    }



    componentDidUpdate(prevProps) {
        if (prevProps.invoices !== this.props.invoices) {
            if (this.props.customer === undefined) {


                const invoices = this.props.invoices.map((invoice) => (
                    <div key={invoice.id} className="list-item list-arrow" onClick={() => this.props.invoiceHandler(invoice)}>
                        <span style={{ float: "right", marginRight: "100px" }}>{formatCurrency(invoice.total)}</span>
                        <h4>Invoice {invoice.invoiceNumber}</h4>
                        <span style={{ float: "right", marginRight: "100px" }}>{invoice.customerName}</span>
                        <div>{format(new Date(invoice.dateTime * 1000), 'dd/MM/yyyy')}</div>
                    </div>
                ));

                this.setState({
                    invoices,
                    loading: false
                });

            }
        }
    }


    getInvoices = () => {
        if (this.props.customer === undefined) {

            this.props.forceRefreshHandler()


            const invoices = this.props.invoices.map((invoice) => (
                <div key={invoice.id} className="list-item list-arrow" onClick={() => this.props.invoiceHandler(invoice)}>
                    <span style={{ float: "right", marginRight: "100px" }}>{formatCurrency(invoice.total)}</span>
                    <h4>Invoice {invoice.invoiceNumber}</h4>
                    <span style={{ float: "right", marginRight: "100px" }}>{invoice.customerName}</span>
                    <div>{format(new Date(invoice.dateTime * 1000), 'dd/MM/yyyy')}</div>
                </div>
            ));

            this.setState({
                invoices,
                loading: false
            });

        } else {
            const formData = new FormData();
            formData.append('userId',this.props.user.id);


            Axios
                .post(API.office.getInvoices, formData)
                .then((res) => {

                    if (res.data.success) {

                        const invoices = res.data.data
                            .filter(invoice => invoice.customerID === this.props.customer.customerID)
                            .map((invoice, c) => {
                                return <div className="list-item list-arrow" onClick={() => this.props.invoiceHandler(invoice)}>
                                    <span style={{float:"right",marginRight:"100px"}}>{formatCurrency(invoice.total)}</span>
                                    <h4>Invoice {invoice.invoiceNumber}</h4>
                                    <span style={{float:"right",marginRight:"100px"}}>{invoice.customerName}</span>

                                    <div>{format(new Date(invoice.dateTime * 1000), 'dd/MM/yyyy')}
                                    </div>
                                </div>;
                            });
                        this.setState({
                            invoices:invoices,
                            loading : false
                        })
                    } else {
                        this.setState({
                            invoices:[],
                            loading : false
                        })
                    }
                }).catch(error => {


                console.log(error.response)

            });
        }

    }

    render() {


        return(

            <div className="main-wrap">
                <div className="cell small-12"><BackButton backHandler={this.props.backHandler} /></div>
                <div className="cell small-12 no-pad">

                    <div className="custlist">

                        <div className="header">
                             <span className="bold-text">{this.state.invoices.length} invoices</span>
                        </div>
                        <div className="padit">
                            Select an Invoice
                        </div>
                        <div className="content">


                            <div className="cell small-12">

                                {this.state.loading ? (
                                    <div>Loading...</div>
                                ) : (
                                    <div>
                                        {this.state.invoices}
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>
                </div>


            </div>
        );

    }


}

export default InvoiceList;
