import React from 'react'
import OfficeHome from './office/OfficeHome'
import BreaktimePosts from './breaktime/BreaktimePosts'
import SettingsHome from './settings/SettingsHome'
import {SECTIONS} from '../resources/strings'
import {Route, Switch} from "react-router-dom";
import WebsiteLanding from "./websites/WebsiteLanding";
import WebsiteEditor from "./websites/WebsiteEditor";
import WebsiteReviews from "./websites/WebsiteReviews";
import WebsiteSettings from "./websites/WebsiteSettings";

const NotFound = () => <div>Page not found</div>;

class AppSection extends React.Component {


    render() {

        if (this.props.subscription !== null && this.props.subscription.premium === 1) {

            return (

                <Switch>
                    <Route path={'/'+ SECTIONS.OFFICE} exact render={(props) => <OfficeHome showMenuHandler={this.props.showMenuHandler}
                                                                                            user={this.props.user} {...props}/>}  />
                    <Route path={'/'+ SECTIONS.BREAK_TIME} exact render={(props) => <BreaktimePosts showMenuHandler={this.props.showMenuHandler}
                                                                                                    user={this.props.user}
                                                                                                    {...props} />}  />

                    <Route path={'/'+ SECTIONS.SETTINGS} exact render={(props) => <SettingsHome showMenuHandler={this.props.showMenuHandler}
                                                                                                logoutHandler={this.props.logoutHandler}
                                                                                                user={this.props.user} {...props} />} />

                    <Route path={"/" + SECTIONS.WEBSITES} exact
                           render={(props) => <WebsiteLanding user={this.props.user} {...props} />}
                           forceReg={true} />

                    <Route path={"/" + SECTIONS.WEBSITES + "/edit"}
                           render={(props) => <WebsiteEditor {...props} /> }
                           forceReg={true} />

                    <Route path={"/" + SECTIONS.WEBSITES + "/reviews"}
                           render={(props) => <WebsiteReviews {...props} /> }
                           forceReg={true} />

                    <Route path={"/" + SECTIONS.WEBSITES + "/settings"}
                           render={(props) => <WebsiteSettings />}
                           forceReg={true} />

                    <Route path={'/'} render={(props) => <OfficeHome
                        user={this.props.user}
                        logoutHandler={this.props.logoutHandler}
                        showMenuHandler={this.props.showMenuHandler} {...props} />}  />
                    <Route component={NotFound} />

                </Switch>


            );

        } else {


            return (

                <Switch>
                    <Route path={'/'+ SECTIONS.OFFICE} exact render={(props) => <WebsiteLanding user={this.props.user} {...props} />}
                           forceReg={true} />
                    <Route path={'/'+ SECTIONS.BREAK_TIME} exact render={(props) => <WebsiteLanding user={this.props.user} {...props} />}
                           forceReg={true} />

                    <Route path={'/'+ SECTIONS.SETTINGS} exact render={(props) => <SettingsHome showMenuHandler={this.props.showMenuHandler}
                                                                                                logoutHandler={this.props.logoutHandler}
                                                                                                user={this.props.user} {...props} />} />

                    <Route path={"/" + SECTIONS.WEBSITES} exact
                           render={(props) => <WebsiteLanding user={this.props.user} {...props} />}
                           forceReg={true} />

                    <Route path={"/" + SECTIONS.WEBSITES + "/edit"}
                           render={(props) => <WebsiteEditor {...props} /> }
                           forceReg={true} />

                    <Route path={"/" + SECTIONS.WEBSITES + "/reviews"}
                           render={(props) => <WebsiteReviews {...props} /> }
                           forceReg={true} />

                    <Route path={"/" + SECTIONS.WEBSITES + "/settings"}
                           render={(props) => <WebsiteSettings />}
                           forceReg={true} />

                    <Route path={'/'} render={(props) => <WebsiteLanding user={this.props.user} {...props} />}
                           forceReg={true} />
                    <Route component={NotFound} />

                </Switch>


            );


        }

    }

}

export default AppSection;
