import React from 'react'
import BackButton from "../common/BackButton"
import {API} from '../../api/APIClient'
import Axios from 'axios'
import SimpleAlertDialog, {ModalButton} from "../common/SimpleAlertDialog";


class NewCustomer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedCustomer: this.props.customer,
            customerID: "",
            firstname: "",
            surname: "",
            address1: "",
            address2: "",
            town: "",
            county: "",
            postcode: "",
            telephone: "",
            email: "",
            sendReminders: 0,
            showSendResult: false,
            showSpinner: false,
            showPostCode : false,
            alertModalOpen : false,
            alertModalTitle : null,
            alertModalMessage: null,
            alertModalButtons : []
        };
        this.handleChange = this.handleChange.bind(this);

        if (this.props.customer) {
            let cust = this.props.customer
            this.state = {
                selectedCustomer: this.props.customer,
                customerID: cust.customerID,
                firstname: cust.firstname,
                surname: cust.surname,
                address1: cust.address1,
                address2: cust.address2,
                town: cust.town,
                county: cust.county,
                postcode: cust.postcode,
                telephone: cust.telephone,
                email: cust.email,
                sendReminders: cust.sendReminders,
                showSendResult: false,
                showSpinner: false,
                showPostCode : false,
                alertModalOpen : false,
                alertModalTitle : null,
                alertModalMessage: null,
                alertModalButtons : []
            };
        }
    }


    componentDidMount() {

    }

    hideAlertModal() {
        this.setState({
            alertModalOpen : false
        });
    }

    showAlertModal(title, message, buttons) {
        this.setState({
            alertModalOpen : true,
            alertModalTitle : title,
            alertModalMessage : message,
            alertModalButtons : buttons
        });
    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (name === "sendReminders") {
            const checked = target.checked;

            this.setState({
                [name]: checked
            });

        } else {
            this.setState({
                [name]: value
            });
        }

    }

    postcodeClicked() {
        this.setState((prevState) => ({
            showPostCode: true,
        }));
    }



    hidePostcodeClicked() {
        this.setState((prevState) => ({
            showPostCode: false,
        }));
    }


    saveClicked() {
        if (this.state.firstname.length > 0 && this.state.surname.length > 0) {

            //Submit!
            this.setState((prevState) => ({
                showSpinner: true,
            }));

            var custID = undefined
            if (!this.state.customerID) {
                custID = window.crypto?.randomUUID?.() || Math.random().toString(36).substring(2, 15);
                this.setState((prevState) => ({
                    customerID: custID
                }));
            } else{
                custID = this.state.customerID
            }

            let customer = {
                customerID: custID,
                firstname: this.state.firstname,
                surname: this.state.surname,
                address1: this.state.address1,
                address2: this.state.address2,
                town: this.state.town,
                county: this.state.county,
                postcode: this.state.postcode,
                telephone: this.state.telephone,
                email: this.state.email,
                sendReminders: this.state.sendReminders,
                searchAddresses: [],
                leaveAppliancesAlone : 1
            }
            const formData = new FormData();
            formData.append('data', JSON.stringify(customer));
            Axios
                .post(API.office.submitCustomer, formData)
                .then((res) => {
                    this.setState((prevState) => ({
                        showSendResult: true,
                        showSpinner: false
                    }));

                }).catch(error => {

                this.setState({
                    showSpinner: false,
                });

                console.log(error.response)
                if (error.response.data.error) {

                    alert(error.response.data.error)
                } else {
                    alert("Could not submit customer.  Please check connection and try again.")
                }
            });

        } else {
            alert("Please enter a first name and surname for this customer.")
        }

    }


    setAddress(addr) {
        const addressComponents = addr.split(',');

        if (addressComponents.length >= 7) {
            this.setState((prevState) => ({
                postcode: this.state.lookupPC,
                address1 : addressComponents[0],
                address2 : addressComponents[1],
                town : addressComponents[5],
                county : addressComponents[6],
                lookupPC : ""
            }));
        }

        this.hidePostcodeClicked()
    }
    postcodeSearchClicked() {


        const formData = new FormData();
        formData.append('postcode', this.state.lookupPC );
        Axios
            .post(API.office.getAddresses, formData)
            .then((res) => {
                if(res.data.success && res.data.data.addresses !== undefined) {
                    this.setState((prevState) => ({
                        searchAddresses : res.data.data.addresses.map((addr) => {
                            const cleanedAddress = addr
                                .split(',')
                                .map(part => part.trim())
                                .filter(part => part !== '')
                                .join(', ');

                            return (<div className="list-item list-arrow" onClick={() => this.setAddress(addr)}>{cleanedAddress}</div>)

                        }),
                    }));
                } else {
                    this.showAlertModal(
                        "No Matches",
                        "No addresses found for that postcode.",
                        [

                            {
                                label : "OK",
                                onClick : () => { this.hideAlertModal() }
                            }
                        ]
                    );
                }

            })
            .catch((error) => {
               console.log(error)
            });
    }

    render() {
        return (
            <div className="loginMain">
                <div className="main-wrap">

                    <div className="cell small-12 header">
                        <BackButton backHandler={this.props.backHandler}/>
                    </div>

                    <div className="clearfix"></div>

                    <div className="reg-box">

                        {this.props.customer ?
                            <h1 className="head-title ">Edit Customer</h1>
                        :
                            <h1 className="head-title ">New Customer</h1>
                        }

                        <div className="main-wrap">
                            <div className="cell small-12">
                                <label>
                                    <span className="reg-label">First Name:</span>
                                    <input className="reg-field" type="text" name="firstname" placeholder="First Name"
                                           value={this.state.firstname} onChange={this.handleChange}/>
                                </label>
                            </div>
                            <div className="cell small-12">
                                <label>
                                    <span className="reg-label">Surname:</span>
                                    <input className="reg-field" type="text" name="surname" placeholder="Surname"
                                           value={this.state.surname} onChange={this.handleChange}/>
                                </label>
                            </div>

                            <div className="cell small-12">
                                <div onClick={() => this.postcodeClicked()} className="ga-button">Postcode lookup (optional)</div>

                            </div>

                            <div className="cell small-12">
                                <label>
                                    <span className="reg-label">Address Line 1:</span>
                                    <input className="reg-field" type="text" name="address1"
                                           placeholder="Address Line 1" value={this.state.address1}
                                           onChange={this.handleChange}/>
                                </label>
                            </div>
                            <div className="cell small-12">
                                <label>
                                    <span className="reg-label">Address Line 2:</span>
                                    <input className="reg-field" type="text" name="address2"
                                           placeholder="Address Line 2" value={this.state.address2}
                                           onChange={this.handleChange}/>
                                </label>
                            </div>
                            <div className="cell small-12">
                                <label>
                                    <span className="reg-label">Town/City:</span>
                                    <input className="reg-field" type="text" name="town" placeholder="Town/City"
                                           value={this.state.town} onChange={this.handleChange}/>
                                </label>
                            </div>
                            <div className="cell small-12">
                                <label>
                                    <span className="reg-label">County:</span>
                                    <input className="reg-field" type="text" name="county" placeholder="County"
                                           value={this.state.county} onChange={this.handleChange}/>
                                </label>
                            </div>
                            <div className="cell small-12">
                                <label>
                                    <span className="reg-label">Postcode:</span>
                                    <input className="reg-field" type="text" name="postcode" placeholder="Postcode"
                                           value={this.state.postcode} onChange={this.handleChange}/>
                                </label>
                            </div>
                            <div className="cell small-12">
                                <label>
                                    <span className="reg-label">Contact Number:</span>
                                    <input className="reg-field" type="text" name="telephone"
                                           placeholder="Contact Number" value={this.state.telephone}
                                           onChange={this.handleChange}/>
                                </label>
                            </div>
                            <div className="cell small-12">
                                <label>
                                    <span className="reg-label">Email:</span>
                                    <input className="reg-field" type="text" name="email" placeholder="Email"
                                           value={this.state.email} onChange={this.handleChange}/>
                                </label>
                            </div>


                            <div className="cell small-12">
                                <label>
                                    <span className="reg-label">Reminders</span>
                                    <span className="reg-label tick-text"><input className="reg-field" type="checkbox"
                                                                                 name="sendReminders"
                                                                                 style={{ transform: "scale(1.5)", margin: "5px" }}
                                                                                 checked={this.state.sendReminders}
                                                                                 onClick={this.handleChange}/>
                                        Send reminders to this customer?</span>

                                </label>
                            </div>

                            <div onClick={() => this.saveClicked()} className="ga-button">Save</div>

                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>

                {this.state.showSpinner && (
                    <div className="modal">
                        <div className="modal-content" style={{textAlign:"center"}} >
                            <h2>Please wait</h2>
                            &nbsp;

                            <div>Saving customer details... </div>

                            &nbsp;
                        </div>
                    </div>
                )}

                {this.state.showSendResult && (
                    <div className="modal">
                        <div className="modal-content" style={{textAlign:"center"}} >

                            {this.props.customer ?
                                <h2>Customer Saved</h2>
                                :
                                <h2>Customer Created</h2>
                            }
                            {this.props.customer ?
                                <div>Customer successfully saved</div>
                                :
                                <div>Customer successfully created</div>
                            }
                            <div>&nbsp;</div>
                            <div className="cell small-12 centered"><div onClick={this.props.backHandler} className="ga-button"  style={{width: "80%",marginRight:"0px"}}>OK</div></div>

                            &nbsp;

                        </div>
                    </div>
                )}

                {this.state.showPostCode && (
                    <div className="modal">
                        <div className="modal-content postcode-lookup"  style={{ width: '500px' }}>

                            <div className="main-wrap">
                                
                                 <h2>Find address by postcode </h2>

                                <div className="cell small-12">
                                     <input className="reg-field" type="text" name="lookupPC" placeholder="Postcode"
                                           value={this.state.lookupPC} onChange={this.handleChange}/>
                                </div>
                                <div className="cell small-12"><div onClick={() => this.postcodeSearchClicked()} className="ga-button" >Search</div></div>

                                <div className="cell small-12">
                                    <BackButton backHandler={() => this.hidePostcodeClicked()}/>
                                </div>
                            </div>
                            <div className="cell small-12" style={{ maxHeight: '350px', overflowY: 'auto' }}>
                                {this.state.searchAddresses}
                            </div>
                        </div>

                        <SimpleAlertDialog
                            open={this.state.alertModalOpen}
                            title={this.state.alertModalTitle}
                            message={this.state.alertModalMessage}>
                            {
                                this.state.alertModalButtons.map((button) => (
                                    <ModalButton label={button.label} onClick={button.onClick}
                                                 className={button.className}/>
                                ))
                            }
                        </SimpleAlertDialog>

                    </div>
                )}


            </div>

        );
    }


}

export default NewCustomer;