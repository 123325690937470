import React from 'react'
import BackButton from "../common/BackButton";
import Axios from "axios";
import {API} from "../../api/APIClient";

class Reminders extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            reminders: [],
            today: [],
            upcoming: [],
            loading: true,
            currentReminder: undefined,
            showOptions: false,
            reminderWeeks:2,
            autoReminders:undefined,
            reminderMessage:undefined,
            showResponse: false,
            response : undefined,
            showEmail : false,
            email: undefined,
            showHistory: false,
            history: undefined,
            showSendConfirmation : false,
            showSendResult : false,
        }

        this.handleChange = this.handleChange.bind(this);

    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (name === "autoRemindersEdit") {
            this.setState({
                [name]: target.checked
            });
        } else {
            this.setState({
                [name]: value
            });
        }



    }

    componentDidMount() {

        this.getReminders();
        this.getReminderSettings();
    }

    getReminderSettings = () => {

        Axios
            .get(API.office.getReminderSettings)
            .then((res) => {

                if (res.data.success) {


                    this.setState({
                        reminderWeeks:res.data.data.reminderWeeks,
                        autoReminders:res.data.data.autoReminders,
                        reminderMessage:res.data.data.reminderMessage,
                        showSettings: false
                    })
                }
            }).catch(error => {


            console.log(error.response)

        });
    }

    getReminders = () => {

        Axios
            .get(API.office.getReminders)
            .then((res) => {

                if (res.data.success) {

                    const upcoming = res.data.data.upcoming.map((reminder, c) => {
                        return <div className="reminder-item" onClick={() => this.showOptions(reminder)}>
                            <div><h2>{reminder.name}</h2>
                            <div>{reminder.address}</div>
                            <h4>Reminder Due: {reminder.dueDate}</h4>
                            <h4>Anniversary Due: {reminder.formDue}</h4>
                            <div>{reminder.formType}</div></div>

                            <div className="item-img"><div className={reminder.status}></div>
                                <h4>{reminder.status}</h4>
                            </div>
                        </div>;
                    });

                    const today = res.data.data.today.map((reminder, c) => {
                        return <div className="reminder-item" onClick={() => this.showOptions(reminder)}>
                            <div><h2>{reminder.name}</h2>
                            <div>{reminder.address}</div>
                            <h4>Reminder Due: {reminder.dueDate}</h4>
                            <h4>Anniversary Due: {reminder.formDue}</h4>
                            <div>{reminder.formType}</div></div>

                            <div className="item-img"><div className={reminder.status}></div>
                                <h4>{reminder.status}</h4>
                            </div>
                        </div>;
                    });

                    const reminders = res.data.data.reminders.map((reminder, c) => {


                        return <div className="reminder-item" onClick={() => this.showOptions(reminder)}>
                            <div><h2>{reminder.name}</h2>
                            <div>{reminder.address}</div>
                            <h4>Reminder Due: {reminder.dueDate}</h4>
                            <h4>Anniversary Due: {reminder.formDue}</h4>
                            <div>{reminder.formType}</div></div>

                            <div className="item-img"><div className={reminder.status}></div>
                                <h4>{reminder.status}</h4>
                            </div>
                        </div>;
                    });
                    this.setState({
                        upcoming:upcoming,
                        today:today,
                        reminders:reminders,
                        loading : false
                    })
                } else {
                    this.setState({
                        upcoming: [],
                        today: [],
                        reminders: [],
                        loading: false
                    })
                }


            })
    }





    showOptions(reminder) {
        this.setState((prevState) => ({
            autoRemindersEdit: prevState.autoReminders,
            currentReminder: reminder,
            showOptions: true,
        }));
    }

    hideOptions = () => {
        this.setState((prevState) => ({
            showOptions: false
        }));
    }


    hideSendResult = () => {
        this.setState((prevState) => ({
            showSendResult: false
        }));
    }

    hideSendConfirmation = () => {
        this.setState((prevState) => ({
            showSendConfirmation: false
        }));
    }


    hideHistory = () => {
        this.setState((prevState) => ({
            showHistory: false
        }));
    }


    hideResponse = () => {
        this.setState((prevState) => ({
            showResponse: false
        }));
    }

    hideEmail = () => {
        this.setState((prevState) => ({
            showEmail: false
        }));
    }

    viewResponse = () => {


        this.setState((prevState) => ({
            showOptions: false
        }));

        const formData = new FormData();
        formData.append('superReminderId', this.state.currentReminder.id );

        Axios
            .post(API.office.getSuperReminderResponse, formData)
            .then((res) => {
                this.setState((prevState) => ({
                    showResponse: true,
                    response:res.data.data
                }));

            }).catch(error => {

        });
    }



    viewHistory = () => {

        this.setState((prevState) => ({
            showOptions: false
        }));

        const formData = new FormData();
        formData.append('superReminderId', this.state.currentReminder.id );

        Axios
            .post(API.office.getSuperReminderHistory, formData)
            .then((res) => {
                this.setState((prevState) => ({
                    showHistory: true,
                    history:res.data.data
                }));

            }).catch(error => {

        });
    }



    viewForm = () => {
        this.setState({ showOptions: false });

        Axios.get(API.office.getFormPDF + this.state.currentReminder.formId, {
            responseType: 'blob' // Important to set the response type to blob
        })
            .then((res) => {
                const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(pdfBlob);

                this.setState({
                    pdfUrl: pdfUrl
                });
            })
            .catch((error) => {
                console.error('Error fetching PDF:', error);
            });
    };

    closePDF() {

        this.setState({
            pdfUrl: undefined
        });
    }



    showSendOptions = () => {
        this.setState({ showOptions: false , showSendConfirmation: true });
    }

    sendNow = () => {

        this.setState((prevState) => ({
            showSendConfirmation: false
        }));

        const formData = new FormData();
        formData.append('superReminderId', this.state.currentReminder.id);

        Axios
            .post(API.office.sendReminderNow, formData)
            .then((res) => {
                this.setState((prevState) => ({
                    showSendResult: true
                }));
                this.getReminders()
            })
            .catch((error) => {

            });
    };



    viewEmail = () => {


        this.setState((prevState) => ({
            showOptions: false
        }));

        const formData = new FormData();
        formData.append('superReminderId', this.state.currentReminder.id );

        Axios
            .post(API.office.getSuperReminderEmail, formData)
            .then((res) => {
                this.setState((prevState) => ({
                    showEmail: true,
                    email:res.data.data
                }));

            }).catch(error => {

        });
    }


    showSettings = () => {
        this.setState((prevState) => ({
            autoRemindersEdit: prevState.autoReminders,
            showSettings: true
        }));
    }

    hideSettings = () => {
        this.setState((prevState) => ({
            showSettings: false
        }));
    }

    saveSettings = () => {

         this.setState((prevState) => ({
            showSettings: false
        }));

        const formData = new FormData();
        formData.append('autoReminders', this.state.autoRemindersEdit ? 1 : 0);
        formData.append('reminderMessage', this.state.reminderMessage  );
        formData.append('reminderWeeks', this.state.reminderWeeks  );

        Axios
            .post(API.office.updateReminderSettings, formData)
            .then((res) => {

                this.getReminderSettings()

            }).catch(error => {

        });
    }

    render() {




        if (this.state.pdfUrl) {

            return(

                <div className="main-wrap">
                    <div className="cell small-12"><div  onClick={() => this.closePDF()} className="ga-button ga-back">
                        Back
                    </div></div>
                    <div className="cell small-12 no-pad">

                        <div className="formlist">


                            <div className="content">


                                <div className="cell small-12">


                                    <div style={{ width: "100%", height: "80vh", marginTop: "20px" }}>
                                        <iframe title="pdf"
                                            src={this.state.pdfUrl}
                                            style={{ width: "100%", height: "100%", border: "none" }}
                                        ></iframe>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            );
        } else {
            return(

                <div className="main-wrap">
                    <div className="cell small-12"><BackButton backHandler={this.props.backHandler} /></div>
                    <div className="cell small-12 ">

                        <div className="reminders">


                            <div className="header">
                                <h1 className="head-title office">Reminders</h1>
                            </div>

                            {this.state.autoReminders === false &&
                                (<div className="padit bold-text warn">
                                    Reminders Not Being Sent.  Enable in Settings (cog icon below).
                                </div>)
                            }

                            <div className="content">

                                <div className="cell small-12 titleRow">
                                    <div><div className="bold-text">Reminders Sent</div>
                                        <div>Last 28 days</div></div>
                                    <div className="cog" onClick={this.showSettings}></div>

                                </div>
                                <div className="cell small-12">
                                    {this.state.loading ? (
                                            <div>Loading...</div>
                                        ) :
                                        (
                                            <div>
                                                {this.state.reminders.length > 0 ? this.state.reminders : <div>No reminders sent in the last 28 days</div>}
                                            </div>
                                        )}
                                </div>
                                <div className="cell small-12 titleRow">
                                    <div><div className="bold-text">Reminders Sent Today</div>
                                        <div>{new Intl.DateTimeFormat('en-GB', { weekday: 'long', day: 'numeric', month: 'long' }).format(new Date())}</div></div>
                                </div>
                                <div className="cell small-12">
                                    {this.state.loading ? (
                                            <div>Loading...</div>
                                        ) :
                                        (
                                            <div>
                                                {this.state.today.length > 0 ? this.state.today : <div>No reminders sent today</div>}
                                            </div>
                                        )}
                                </div>
                                <div className="cell small-12 titleRow">
                                    <div><div className="bold-text">Upcoming Reminders</div>
                                        <div>Next 9 weeks</div></div>
                                </div>
                                <div className="cell small-12">

                                    {this.state.loading ? (
                                            <div>Loading...</div>
                                        ) :
                                        (
                                            <div>
                                                {this.state.upcoming.length > 0 ? this.state.upcoming : <div>No upcoming reminders</div>}
                                            </div>
                                        )}

                                </div>


                            </div>

                        </div>
                    </div>

                    {this.state.showSettings && (
                        <div className="modal">
                            <div className="modal-content">
                                <h2>Reminder Settings</h2>
                                <label className="cell small-12 autoemails">Send automatic email reminders:
                                    <input type="checkbox" name="autoRemindersEdit" checked={this.state.autoRemindersEdit} onClick={this.handleChange}/>
                                    <span className="checkmark"></span>
                                </label>


                                <div className="cell small-12">Send reminder emails this many weeks before due:</div>
                                <select className="weeks" value={this.state.reminderWeeks} name="reminderWeeks" onChange={this.handleChange}>
                                    <option value="1">1 Week</option>
                                    <option value="2">2 Weeks</option>
                                    <option value="3">3 Weeks</option>
                                    <option value="4">4 Weeks</option>
                                    <option value="5">5 Weeks</option>
                                    <option value="6">6 Weeks</option>
                                    <option value="7">7 Weeks</option>
                                    <option value="8">8 Weeks</option>
                                    <option value="9">9 Weeks</option>
                                </select>


                                <div className="cell small-12">Your customers will be sent a professionally formatted reminder email. If you wish to send your own message please enter it in the box below. Simply leave this blank to send the detault message.</div>

                                <label>
                                    <textarea className="msg" type="text" name="reminderMessage" placeholder="" onChange={this.handleChange}>{this.state.reminderMessage}</textarea>
                                </label>


                                <div className="cell small-12 centered"><div onClick={this.saveSettings} className="ga-button"  style={{marginRight:"0px",width: "200px"}}>Save Settings</div></div>
                                <div className="cell small-12 centered no-pad"><div onClick={this.hideSettings} className="ga-button" style={{marginRight:"0px",width: "200px",background:"#999"}}>Cancel</div></div>
                                &nbsp;


                            </div>
                        </div>
                    )}


                    {this.state.showOptions && (
                        <div className="modal">
                            <div className="modal-content">
                                <h2>Select an option...</h2>

                                {this.state.currentReminder.status !== "upcoming" &&
                                    <div className="cell small-12 centered modal-btn-wrap"><div onClick={this.viewEmail} className="ga-button modal-btn">View reminder email sent</div></div>
                                }
                                <div className="cell small-12 centered modal-btn-wrap"><div onClick={this.viewResponse} className="ga-button modal-btn">View customer response</div></div>
                                <div className="cell small-12 centered modal-btn-wrap"><div onClick={this.viewHistory} className="ga-button modal-btn">View reminder history</div></div>
                                <div className="cell small-12 centered modal-btn-wrap"><div onClick={this.viewForm} className="ga-button modal-btn">View original form</div></div>

                                {this.state.currentReminder.status === "upcoming" &&
                                    <div className="cell small-12 centered modal-btn-wrap"><div onClick={this.showSendOptions} className="ga-button modal-btn">Send reminder now</div></div>
                                }


                                <div className="cell small-12 centered modal-btn-wrap"><div onClick={this.hideOptions} className="ga-button modal-btn" style={{background:"#999"}}>Cancel</div></div>



                            </div>
                        </div>
                    )}


                    {this.state.showSendConfirmation && (
                        <div className="modal">
                            <div className="modal-content">
                                <h2>Send now?</h2>

                                <div>The customer will be sent the reminder email now, are you sure?</div>

                                <div className="cell small-12 centered"><div onClick={this.sendNow} className="ga-button"  style={{width: "80%"}}>Yes, send it</div></div>


                                <div className="cell small-12 centered"><div onClick={this.hideSendConfirmation} className="ga-button" style={{width: "80%",background:"#999"}}>Cancel</div></div>
                                &nbsp;


                            </div>
                        </div>
                    )}


                    {this.state.showSendResult && (
                        <div className="modal">
                            <div className="modal-content">
                                <h2>Reminder Sent</h2>
                                <div>Reminder sent successfully</div>


                                <div className="cell small-12 centered"><div onClick={this.hideSendResult} className="ga-button"  style={{width: "80%"}}>OK</div></div>


                                &nbsp;


                            </div>
                        </div>
                    )}


                    {this.state.showResponse && (
                        <div className="modal">
                            <div className="modal-content">
                                <h2>Reminder Response</h2>
                                {this.state.response.response === null ? (<div>The customer has not responded</div>) : (<div>

                                    {this.state.response.response === "visit" && <div>{this.state.currentReminder.name} has requested to book a visit in the next {this.state.response.responseTimescale}. They are available for a call in the {this.state.response.responseCallTime} </div>}
                                    {this.state.response.response === "call" && <div>{this.state.currentReminder.name} has requested a callback, and is available in the {this.state.response.responseCallTime} </div>}
                                    {this.state.response.response === "no_contact" && <div>{this.state.currentReminder.name} has requested no further contact. </div>}

                                    <h4>Contact Details</h4>
                                    <h4>Phone</h4>
                                    <div>{this.state.response.responsePhone}</div>
                                    <h4>Email</h4>
                                    <div>{this.state.response.responseEmail}</div>
                                </div>)
                                }

                                <div className="cell small-12 centered"><div onClick={this.hideResponse} className="ga-button"  style={{width: "80%"}}>OK</div></div>
                                &nbsp;


                            </div>
                        </div>
                    )}



                    {this.state.showHistory && (
                        <div className="modal">
                            <div className="modal-content">
                                <h2>Reminder History</h2>
                                <div className={this.state.currentReminder.status === "upcoming" ? "history highlighted" : "history"}>
                                    <div className="upcoming"></div><div className="history-label"><div className="bold-text">Upcoming</div><div>Reminder due on {this.state.currentReminder.dueDate}</div></div>

                                </div>

                                <div className={this.state.currentReminder.status === "upcoming" ? "history faded" : this.state.currentReminder.status === "sent" ? "history highlighted" : "history"}>
                                    <div className="sent"></div><div className="history-label"><div className="bold-text">Sent</div>{this.state.currentReminder.status !== "upcoming" ? <div>Reminder was sent to the customer {this.state.history.find(entry => entry.status === "sent")?.statusDate}</div> : <div>Reminder not yet sent</div>}</div>

                                </div>

                                <div className={(this.state.currentReminder.status === "upcoming" || this.state.currentReminder.status === "sent") ? "history faded" :  this.state.currentReminder.status === "read" ? "history highlighted" : "history"}>
                                    <div className="read"></div><div className="history-label"><div className="bold-text">Read</div>{(this.state.currentReminder.status !== "upcoming" && this.state.currentReminder.status !== "sent") ? <div>Reminder was read  {this.state.history.find(entry => entry.status === "read")?.statusDate}</div> : <div>Not yet read</div>}</div>

                                </div>

                                <div className={this.state.currentReminder.status !== "responded" ? "history faded" :  this.state.currentReminder.status === "responded" ? "history highlighted" : "history"}>
                                    <div className="responded"></div><div className="history-label"><div className="bold-text">Responded</div>{this.state.currentReminder.status === "responded" ? <div>The customer responded to the reminder email on {this.state.history.find(entry => entry.status === "responded")?.statusDate}</div> : <div>Not yet responded</div>}</div>

                                </div>


                                <div className="cell small-12 centered"><div onClick={this.hideHistory} className="ga-button"  style={{width: "80%"}}>OK</div>
                                </div>
                                &nbsp;


                            </div>
                        </div>
                    )}


                    {this.state.showEmail && (
                        <div className="modal" onClick={this.hideEmail}>

                            <div dangerouslySetInnerHTML={{ __html: this.state.email }} />


                        </div>
                    )}

                </div>
            );
        }




    }
}
export default Reminders;
