import React from 'react'
import { API } from '../../api/APIClient'
import moment from 'moment';
import Axios from 'axios'



class Notification extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            read: this.props.read
        }

    }



    render() {

        var profileImg = "";
        if (this.props.profileImageThumb === API.BASE_URL)
        {
            profileImg = <div className="placeholder-icon" />;

        } else {
            profileImg = <img src={this.props.profileImageThumb} width="32" alt="Profile Thumb" />;

        }
         var bgColour = "#e2e8f6";
        if (this.state.read === 1)
        {
            bgColour = "#ffffff";

        }

        var msg = this.props.message;
        if (msg.length > 100)
        {
            msg = msg.substring(0, 100) + "...";
        }
        let dateCreated =  moment(this.props.dateCreated, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');

        return(

            <div className="cell small-12">
                <div className="post-ff" style={{backgroundColor: bgColour}} onClick={e => this.readClicked(e)}>
                    <div className="notif-info">
                        <div className="right"><div className="destroy" onClick={e => this.deleteClick(e)}>Delete</div></div>
                        <div className="left">      <div className="prof-img" onClick={e => this.imgClicked(e)}>
                        {profileImg}
                            </div>
                            </div>

                  <div className="middle">
                      <span className="username">On {dateCreated} {this.props.fromName} {msg}</span>
                  </div>

                    </div>
                </div>
            </div>

        );

    }


    deleteClick(e)
    {
        this.props.deleteHandler(this.props.id);
        e.stopPropagation();
    }

    readClicked(e)
    {
        var postInfo = {postId: this.props.postId, commentId: this.props.commentId}
        this.props.notifClickHandler(postInfo);


        if (this.state.read !== "1")
        {
            this.setState({read: "1"});

            const formData = new FormData();
            formData.append('notificationID', this.props.id);

            Axios
                .post(API.breakTime.markBreaktimeNotificationRead, formData)
                .then((res) => {

                })
        }

        e.stopPropagation();

    }

    imgClicked(e)
    {
        if (this.props.profileImageThumb !== API.BASE_URL)
        {
            this.props.imageHandler([this.props.profileImage])
            e.stopPropagation();
        }

    }
}

export default Notification;
