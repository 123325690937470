import React from 'react'
import NavBar from './nav/NavBar'
import AppSection from './AppSection'
import {SECTIONS} from '../resources/strings'
import { APIHelper, API } from '../api/APIClient'
import Axios from 'axios'
import LoginScreen from './login/LoginScreen'
import { BrowserRouter} from "react-router-dom";
import {Route, Switch} from "react-router-dom";


class GasApp extends React.Component {
    constructor(props) {
        super(props);

        var user = null;
        const usrStr = localStorage.getItem('user');
        if (usrStr != null)
        {
            user = JSON.parse(usrStr)

//            alert("setting to " + user.id + " " + user.token);
            APIHelper.setAuthDetails(user.token, user.id);

        }

        this.state = {
            currentSection: SECTIONS.OFFICE,
            showMenu: true,
            subscription: null,
            user: user};
    }

    setShowMenu(shouldShow)
    {
        this.setState({showMenu:shouldShow});
    }



    getSubInfo()
    {
        Axios
            .get(API.user.getSubscriptionData)
            .then((res) => {

                if (res.data.success) {

                    if (this.state.user.state != res.data.data.userType)
                    {
                        var updatedUsr = this.state.user;
                        updatedUsr.state = res.data.data.userType;

                        localStorage.setItem("user", JSON.stringify(updatedUsr));


                        this.setState({
                            user : updatedUsr,
                            subscription: res.data.data
                        })
                    } else {

                        this.setState({
                            subscription: res.data.data
                        })
                    }

                }

            }).catch(error => {

                if (error.response.data.error && error.response.data.error === "Not Authorised") {
                    //log out
                    this.handleLogout();
                }
            });
    }


    handleMenuClick(section) {

        this.setState({
            currentSection: section,
            currentSubScreen: null});
    }

    updateUserObject(user)
    {
        localStorage.setItem("user", JSON.stringify(user));
        this.setState({user : user});
    }
    handleLogin(user)
    {
        localStorage.setItem("user", JSON.stringify(user));
        this.setState({user : user});
        APIHelper.setAuthDetails(user.token, user.id);
        document.location.href="/";
    }

    handleLogout()
    {
        localStorage.removeItem("user");
        this.setState({user : null});
        APIHelper.removeAuthDetails();


    }

    render() {


        if (this.state.user == null)
        {
            return (
                <BrowserRouter>

                    <Switch>
                        <Route path={'/register'} exact render={(props) => <LoginScreen
                            loginHandler={(user) => this.handleLogin(user)}
                            forceReg={true}
                         {...props} />}  />
                        <Route path={'/'}  render={(props) => <LoginScreen
                            loginHandler={(user) => this.handleLogin(user)}
                         {...props} />}  />

                    </Switch>
                </BrowserRouter>
            );

        } else {



                if (this.state.subscription == null)
                {
                    this.getSubInfo();
                }



                return (
                    <BrowserRouter>
                        <NavBar showMenu={this.state.showMenu}
                                subscription={this.state.subscription}
                                menuClickHandler={(section) => this.handleMenuClick(section)}
                        />

                        <AppSection showMenuHandler={(shouldShow) => this.setShowMenu(shouldShow)}
                                    section={this.state.currentSection}
                                    user={this.state.user}
                                    subscription={this.state.subscription}
                                    userHandler={(usr) => this.updateUserObject(usr)}
                                    logoutHandler={() => this.handleLogout()}

                        />
                    </BrowserRouter>
                );



        }

    }
}

export default GasApp;
