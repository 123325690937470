import React from 'react'
import { API } from '../../api/APIClient'
import moment from 'moment';

function PostUserInfo(props) {


    var profileImg = "";
    if (props.profileImageThumb === API.BASE_URL)
    {
        profileImg = <div className="placeholder-icon" />;

    } else {
        profileImg = <img src={props.profileImageThumb} width="32" alt="Profile Thumb" />;

    }

    let dateCreated =  moment(props.dateCreated, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
    let timeCreated =  moment(props.dateCreated, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');

    return(


    <div className="user-info">
                    <div className="prof-img" onClick={imgClicked}>
                        {profileImg}
                    </div>
                    <div className="user-deets">
                        <div className="upper-deets">
                            <span className="username">{props.givenName + " " + props.familyName}</span>
                            {props.isInstaller == 1 && <div className="post-header-icon isInstaller"/>}
                            {props.isBreakdown == 1 && <div className="post-header-icon isBreakdown" />}
                            {props.isMisc == 1 && <div className="post-header-icon isMisc"/>}
                            {props.isApprentice == 1 && <div className="post-header-icon isApprentice"/>}
                            {props.isTrainingCentreUser == 1 && <div className="post-header-icon isTrainingCentreUser"/>}
                            {props.hasValidGasCard == 1 && <div className="post-header-icon hasValidGasCard"/>}
                        </div>
                        <div className="lower-deets">
                            <span className="date-created">{dateCreated} at {timeCreated}</span>
                        </div>
                    </div>
        </div>
    );


    function imgClicked(e)
    {
        if (props.profileImageThumb !== API.BASE_URL)
        {
            props.imageHandler([{src:props.profileImage}])
            e.stopPropagation();
        }

    }
}

export default PostUserInfo;
