/**
 * Created by Simon on 21/08/19.
 */
import React from 'react'
import { API } from '../../api/APIClient'
import Axios from 'axios'
import BackButton from "../common/BackButton"
import Notification from "./Notification"
import "yet-another-react-lightbox/styles.css";
import PostComments from './PostComments'


class Notifications extends React.Component {

    constructor(props) {
        super(props);

        this.SCREENS = {
            HOME: "home",
            COMMENTS: "comments"
        };

        this.state = {
           loading : false,
            lightboxOpen: false,
            imageIDX:0,
            currentScreen: this.SCREENS.HOME,
            navStack: []
        };

    }


    reloadCurrentPost()
    {
        const formData = new FormData();
        formData.append('postId', this.state.postToShow.id);

        Axios
            .post(API.breakTime.getPost, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        postToShow : res.data.data
                    })
                }
            })
    }

    handleBackClick ()
    {
        const navStack = this.state.navStack.slice();

        const backScreen = navStack[navStack.length - 1];

        this.setState({
            currentScreen: backScreen,
            navStack: navStack.slice(0,navStack.length - 1)
        });
        this.getNotifications();

    }



    notifClick(postInfo)
    {
        const formData = new FormData();
        formData.append('postId', postInfo.postId);
        Axios
            .post(API.breakTime.getPost, formData)
            .then((res) => {

                if (res.data.success) {

                    const oldScreen = this.state.currentScreen;
                    const navStack = this.state.navStack.concat([oldScreen])
                    this.setState({
                        currentScreen: this.SCREENS.COMMENTS,
                        navStack: navStack,
                        postToShow : res.data.data,
                        commentToScrollTo : postInfo.commentId
                    });

                }
            })
    }

    deleteClick(notifID)
    {

        var idx2Go = -1;
        var c=0;
        this.state.notifications.forEach(function (notif) {

           if (notif.props.id === notifID)
           {
               idx2Go = c;
           }
            c++;
        });

        if (idx2Go >= 0)
        {
            var newNodifs = this.state.notifications.slice();
            newNodifs.splice(idx2Go, 1);
            this.setState({notifications: newNodifs})

            const formData = new FormData();
            formData.append('notificationID', notifID);

            Axios
                .post(API.breakTime.deleteNotification, formData)
                .then((res) => {

                })
        }

    }
    imageClick(images)
    {
        this.setState({ligtboxImages: images, lightboxOpen:true})
    }

    componentDidMount() {

        this.getNotifications();

    }
    deleteAllClicked() {
        const formData = new FormData();

        Axios
            .post(API.breakTime.deleteAllNotifications, formData)
            .then((res) => {

                this.getNotifications();

            })

    }

    getNotifications = () => {

        this.setState({ loading: true });
        const formData = new FormData();

        Axios
            .post(API.breakTime.getNotifications, formData)
            .then((res) => {
                if (res.data.success) {
                    const notifs = res.data.data
                        .filter(notif => notif.link && notif.link.startsWith('gasapp://breaktime/'))  // Filter notifications based on link
                        .map((notif, c) => {

                            const linkParts = notif.link.split('/');
                            const itemIDBase64 = linkParts[linkParts.length - 1].split('=')[0];
                            let itemID = -1;
                            if (itemIDBase64) {
                                try {
                                    const decodedString = atob(itemIDBase64);
                                    const possibleItemID = parseInt(decodedString, 10);
                                    if (possibleItemID > 0) {
                                        itemID = possibleItemID;
                                    }
                                } catch (e) {
                                    console.error('Error decoding base64 item ID:', e);
                                }
                            }
                            return <Notification
                                key={notif.id}
                                profileImageThumb={notif.profileImageThumb}
                                profileImage={notif.profileImage}
                                fromName={notif.fromName}
                                dateCreated={notif.createdAt}
                                read={notif.read}
                                id={notif.id}
                                postId={itemID}
                                commentId={notif.commentId}
                                idx={c}
                                message={notif.message}
                                imageHandler={(imgs) => this.imageClick(imgs)}
                                deleteHandler={(notifid) => this.deleteClick(notifid)}
                                notifClickHandler={(postInfo) => this.notifClick(postInfo)}
                            />;
                        });
                    this.setState({
                        notifications: notifs,
                        loading: false
                    });
                }
            });


    }

    render() {

        switch (this.state.currentScreen) {

            case this.SCREENS.HOME :
                return (

                    <div className="main-wrap">
                        <div className="cell small-6 no-side">
                            <BackButton backHandler={this.props.backHandler} /></div>                        <div className="cell small-6 no-side" style={{textAlign: "right"}}>

                    <div onClick={() => this.deleteAllClicked()} className="ga-button delete">Delete All</div>
                        </div>
                        <div className="create-box">
                            <div className="cell small-12">
                                <h2>Notifications</h2>

                    {this.state.loading ? <div>Loading...</div> : <div>{this.state.notifications}</div>}

                            </div>
                        </div>

                    </div>
                );

            case this.SCREENS.COMMENTS :
                return (<PostComments post={this.state.postToShow}
                    backHandler={() => this.handleBackClick()}
                    commentToScrollTo = {this.state.commentToScrollTo}
                    user={this.props.user}
                    likeHandler={(postID) => this.likeClicked(postID)}
                    refreshHandler={() => this.reloadCurrentPost()}
                />);
            default :
                return (<div>unknown screen</div>);
        }

    }
}


export default Notifications;

